import { createRouter, createWebHistory } from "vue-router";
import {getemailaccount} from "../api/emailextract";
import {ElMessage, ElMessageBox} from "element-plus";
import {searchTonnagepayed} from "../api/tonnage";
import NProgress from "nprogress";

const routes = [
  {
    path: "/",
    name: "Index",
    component: () =>
      import("../views/index/Index.vue"),
    redirect: '/searchindex',
    children: [
      {
        path: "searchindex",
        name: "searchindex",
        component: () =>
          import("../views/search-index/SearchIndex.vue"),
      },
      {
        path: "searchlist",
        name: "searchlist",
        component: () =>
          import("../views/search-list/SearchList.vue"),
        meta: {
          keepAlive: true
        }
      },
      {
        path: "tonnage",
        name: "tonnage",
        component: () =>
          import("../views/subindex/SubIndex.vue"),
        children: [
          {
            path: "addtonnage/:eord?/:id?",
            name: "addtonnage",
            component: () =>
              import("../views/tonnage/AddTonnage.vue"),
          },
          {
            path: "tcmatching",
            name: "tcmatching",
            component: () =>
              import("../views/tonnage/TcMatching.vue"),
          },
          {
            path: "ttmatching",
            name: "ttmatching",
            component: () =>
              import("../views/tonnage/TtMatching.vue"),
          },
          {
            path: "matchdetail/:tonnage_id/:tag/:tag_id",//这个路由是匹配对比页面，需要接受两个id，
            // 一个是船舶动态的id,这个是确定的
            // 还有一个是货盘或者租船的所以需要传入一个tag来确定是哪一个
            name: "matchdetail",
            component: () =>
              import("../views/tonnage/MatchDetail.vue"),
          },
          {
            path: "tonmatching/:id",
            name: "tonmatching",
            component: () =>
              import("../views/tonnage/TonMatching.vue"),
          },
         /* {
            path: "tonnage_analysis",
            name: "tonnage_analysis",
            component: () =>
              import("../views/tonnage/DataAnalysis.vue"),
          },*/
          {
            path: "search_tonnage",
            name: "search_tonnage",
            component: () =>
              import("../views/tonnage/SearchTonnage.vue"),
            meta: {
              keepAlive: true
            }
          },
        ]
      },
      {
        path:"cargo",
        name:"cargo",
        component: () =>
        import("../views/subindex/SubIndex.vue"),
        children:[
          {
            path: "addcargo/:eord?/:id?",
            name: "addcargo",
            component: () =>
            import("../views/cargo/AddCargo.vue"),
          },
          {
            path: "matching_cargo/:id?",
            name: "matching_cargo",
            component: () =>
            import("../views/cargo/MatchingCargo.vue"),
          },
          /*{
            path: "cargo_date_analysis",
            name: "cargo_date_analysis",
            component: () =>
            import("../views/cargo/CargoDateAnalysis.vue"),
          },*/
          {
            path: "search_cargo",
            name: "search_cargo",
            component: () =>
              import("../views/cargo/SearchCargo.vue"),
            meta:{
              keepAlive:true, //需要被缓存
              isBack:false     //是否为详情页
            }
          },
          {
            path: "ctmatching/:id",
            name: "ctmatching",
            component: () =>
              import("../views/cargo/CtMatching.vue"),
            meta:{
              keepAlive:true, //需要被缓存
              isBack:false     //是否为详情页
            }
          },
          {
            path: "ctmatchdetail/:cargo_id/:tag/:tag_id",
            name: "ctmatchdetail",
            component: () => import("../views/cargo/CtMatchDetail.vue"),
          },
        ]
      },
      //复制tonage，改路径名
      {
        path: "ship_trade",
        name: "ship_trade",
        component: () =>
          import("../views/subindex/SubIndex.vue"),
        children: [
          {
            path: "addship_trade/:eord?/:id?",
            name: "addship_trade",
            component: () =>
              import("../views/ship_trade/AddShipTrade.vue"),
          },

        /*  {
            path: "shiptrade_analysis",
            name: "shiptrade_analysis",
            component: () =>
              import("../views/ship_trade/DataAnalysis.vue"),
          },*/
          {
            path: "search_shiptrade",
            name: "search_shiptrade",
            component: () =>
              import("../views/ship_trade/SearchShipTrade.vue"),
            meta: {
              keepAlive: true, //需要被缓存
              isBack: false     //是否为详情页
            }
          },
        ]
      },
      {
        path: "tct",
        name: "tct",
        component: () =>
          import("../views/subindex/SubIndex.vue"),
        children: [
          {
            path: "addtct/:eord?/:id?",
            name: "addtct",
            component: () =>
              import("../views/tct/AddTCT.vue"),
          },
         /* {
            path: "data_analysis",
            name: "data_analysis",
            component: () =>
              import("../views/tct/DataAnalysis.vue"),
          },*/
          {
            path: "search_tct",
            name: "search_tct",
            component: () =>
              import("../views/tct/SearchTCT.vue"),
            meta: {
              keepAlive: true
            }
          },
          {
            path: "tctmatching",
            name: "tctmatching",
            component: () =>
              import("../views/tct/TctMatching.vue"),
          },
          {
            path: "ttmatch/:id",
            name: "ttmatch",
            component: () =>
              import("../views/tct/TtMatch.vue"),
          },
          {
            path: "ttmatchdetail/:tct_id/:tag/:tag_id",//这个路由是匹配对比页面，需要接受两个id，
            // 一个是船舶动态的id,这个是确定的
            // 还有一个是货盘或者租船的所以需要传入一个tag来确定是哪一个
            name: "ttmatchdetail",
            component: () =>
              import("../views/tct/MatchDetail.vue"),
          },
        ]
      },
      {
        path: "shipdate",
        name: "shipdate",
        component: () =>
          import("../views/subindex/SubIndex.vue"),
        children:[
          {
            path: "search_shipdate",
            name: "search_shipdate",
            component: () =>
              import("../views/shipdate/search_shipdate.vue"),
          },
          {
            path: "add_shipdate/:eord?/:id?",
            name: "add_shipdate",
            component: () =>
              import("../views/shipdate/add_shipdate.vue"),
          },
          {
            path: "match_shipdate",
            name: "match_shipdate",
            component: () =>
              import("../views/shipdate/match_shipdate.vue"),
          },
          {
            path: "shipdatedetail/:shipdate_id/:tag/:tag_id",
            name: "shipdatedetail",
            component: () =>
              import("../views/shipdate/matchdetail.vue"),
          },
          {
            path: "date_match/:id",
            name: "date_match",
            component: () =>
              import("../views/shipdate/date_match.vue"),
          }]
      },
      {
        path: "estimate",
        name: "estimate",
        component: () =>
          import("../views/subindex/SubIndex.vue"),
        children: [
          {
            path: "searchresult",
            name: "searchresult",
            component: () =>
              import("../views/estimate/SearchResult.vue"),
          },
          {
            path: "voyageestimate/:eord?/:id?",
            name: "voyageestimate",
            component: () =>
              import("../views/estimate/VoyageEstimate.vue"),
          }
        ]
      },
      {
        path: "extract",
        name: "extract",
        component: () =>
          import("../views/subindex/SubIndex.vue"),
        children: [
          {
            path: "emailset/:id?",
            name: "emailset",
            component: () =>
              import("../views/email/EmailSetting.vue"),
            beforeEnter:(to,from,next)=>{
              if(to.params.id == ''){
                getemailaccount().then(response =>{
                  if(response.error_code == 200){
                    if(response.data!=null){
                      if(response.data.count == 0){
                        ElMessageBox.confirm(
                            '没有找到您的邮箱，请先去添加！',
                            {
                              confirmButtonText: '确认',
                              cancelButtonText: '取消',
                              type: 'info',
                            }
                        ).then(() => {
                          next({path:'/extract/emailaccount'})
                        }).catch(() =>{
                          NProgress.done()
                        })
                      }
                      else{
                        to.params.id = response.data.results[0].id  //默认首选第一个邮箱账号
                        next()
                      }
                    }
                  }
                })
              }
              else next()
            }
          },
          {
            path: "emailaccount",
            name: "emailaccount",
            component: () =>
              import("../views/email/EmailAccount.vue"),
          },
          {
            path: "emailextract/:id?",
            name: "emailextract",
            component: () =>
              import("../views/email/EmailExtraction.vue"),
          },
          {
            path: "emaildownload/:id?",
            name: "emaildownload",
            component: () =>
              import("../views/email/EmailDownload.vue"),
          },
          {
            path: "emaillist",
            name: "emaillist",
            component: () =>
                import("../views/subindex/SidebarIndex.vue"),
            children:[
              {
              path: "emailcargo",
              name: "emailcargo",
              component: () =>
                import("../views/email/EmailCargo.vue"),
              },{
                path: "emailtct",
                name: "emailtct",
                component: () =>
                  import("../views/email/EmailTct.vue"),
              },{
                path: "emailtonnage",
                name: "emailtonnage",
                component: () =>
                  import("../views/email/EmailTonnage.vue"),
              },
            ],
          },
        ]
      },
      {
        path: "person",
        name: "person",
        component: () =>
          import("../views/subindex/SubIndex.vue"),
        children:[
          {
            path: "personalmessage",
            name: "personalmessage",
            component: () =>
              import("../views/subindex/SidebarIndex.vue"),
            children:[
              {
                path: "mymessage/:eord?",
                name: "mymessage",
                component: () =>
                  import("../views/personalCenter/PersonalMessage.vue"),
              },
              {
                path:"integral",
                name:"integral",
                component:() =>
                  import("../views/personalCenter/Integral.vue"),
              },
              // {
              //   path:"purse",
              //   name:"purse",
              //   component:() =>
              //     import("../views/personalCenter/Purse.vue"),
              // },
              {
                path: "deposit",
                name: "deposit",
                component: () =>
                  import("../views/personalCenter/Deposit.vue"),
              },
              {
                path: "weixin",
                name: "weixin",
                component: () =>
                  import("../views/personalCenter/Weixin.vue"),
              },
            ]
          },
          {
            path: "myissue",
            name: "myissue",
            component: () =>
              import("../views/subindex/SidebarIndex.vue"),
            children: [
              {
                path: "mytonnage",
                name: "mytonnage",
                component: () =>
                  import("../views/person/Mytonnage.vue"),
              },
              {
                path: "mycargo",
                name: "mycargo",
                component: () =>
                  import("../views/person/Mycargo.vue"),
              },
              {
                path:"mytct",
                name:"mytct",
                component:() =>
                  import("../views/person/mytct.vue"),
              },
              {
                path:"myshiptrade",
                name:"myshiptrade",
                component:() =>
                  import("../views/person/myshiptrade.vue"),
              },
              {
                path:"myvoyage",
                name:"myvoyage",
                component:() =>
                  import("../views/person/myvoyage.vue"),
              },
              {
                path:"myestimate",
                name:"myestimate",
                component:() =>
                  import("../views/person/myestimate.vue"),
              },
            ]
          },
          {
            path: "mycollect",
            name: "mycollect",
            component: () =>
              import("../views/subindex/SidebarIndex.vue"),
            children: [
              {
                path: "mytonnagecoll",
                name: "mytonnagecoll",
                component: () =>
                  import("../views/person/Mytonnagecoll.vue"),
              },

              {
                path: "mycargocoll",
                name: "mycargocoll",
                component: () =>
                  import("../views/person/Mycargocoll.vue"),
              },

              {
                path:"mytctcoll",
                name:"mytctcoll",
                component:() =>
                  import("../views/person/mytctcoll.vue"),
              },
            {
                path:"myshiptradecoll",
                name:"myshiptradecoll",
                component:() =>
                  import("../views/person/myshiptradecoll.vue"),
              },
              {
                path:"myvoyagecoll",
                name:"myvoyagecoll",
                component:() =>
                    import("../views/person/myvoyagecoll.vue"),
              },
              {
                path:"myestimatecoll",
                name:"myestimatecoll",
                component:() =>
                    import("../views/person/myestimatecoll.vue"),
              },
              {
                path:"tcmatchingcoll",
                name:"tcmatchingcoll",
                component:() =>
                    import("../views/person/tcmatchingcoll.vue"),
              },
              {
                path:"ttmatchingcoll",
                name:"ttmatchingcoll",
                component:() =>
                    import("../views/person/ttmatchingcoll.vue"),
              },{
                path:"shipdatematchcoll",
                name:"shipdatematchcoll",
                component:() =>
                    import("../views/person/shipdatematchcoll.vue"),
              },
            ]
          },

          {
            path: "searchhistory",
            name: "searchhistory",
            component: () =>
              import("../views/personalCenter/SearchHistory.vue"),
          },

        ]
      },
      {
        path:'analysis',
        name:'analysis',
        component: () =>
            import("../views/subindex/SubIndex.vue"),
        children:[
          {
            path: "tct_analysis",
            name: "tct_analysis",
            component: () =>
              import("../views/tct/DataAnalysis.vue"),
          },
          {
            path: "cargo_analysis",
            name: "cargo_analysis",
            component: () =>
              import("../views/cargo/CargoDateAnalysis.vue"),
          },
          {
            path: "tonnage_analysis",
            name: "tonnage_analysis",
            component: () =>
              import("../views/tonnage/DataAnalysis.vue"),
          },
        ],
      },
      {
        path: "login",
        name: "login",
        component: () =>
          import("../views/index/login.vue"),
      },
         {
        path: "register",
        name: "register",
        component: () =>
          import("../views/index/register.vue"),
      },
      {
        path: "jihuo",
        name: "jihuo",
        component: () =>
          import("../views/index/jihuo.vue"),
      },
      {
        path: "password",
        name: "password",
        component: () =>
          import("../views/index/password.vue"),
      },
      {
        path: "resetpassword",
        name: "resetpassword",
        component: () =>
          import("../views/index/resetpassword.vue"),
      },
      {
        path: "resetSuccess",
        name: "resetSuccess",
        component: () =>
          import("../views/index/resetSuccess.vue"),
      },
      {
        path: "resetFail",
        name: "resetFail",
        component: () =>
          import("../views/index/resetFail.vue"),
      },
    ]
  },


];

const router = createRouter({
  mode: 'history', // 为了打包
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
