
//判断当前显示是否为移动端
// export default function _isMobile() {
//         let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
//         return flag
// }

export default function _isMobile() {
          var ua = navigator.userAgent
          let isWindowsPhone = /(?:Windows Phone)/.test(ua)
          //let isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone
          let isAndroid = /(?:Android)/.test(ua)
          let isFireFox = /(?:Firefox)/.test(ua)
          //let isChrome = /(?:Chrome|CriOS)/.test(ua)
          let isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua))
          let isPhone = /(?:iPhone)/.test(ua) && !isTablet
          //let isPc = !isPhone && !isAndroid && !isSymbian

          if(isAndroid || isPhone) {
                  return true
          } else {
                  return false
          }
      }