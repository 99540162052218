const en = {
  home:'Home',
  card:'Card',
  list:'List',
  T:'MT',
  m:'M',
  a:'',
  Cbm:'Cbm',
  tempsave:'temporary storage',
  savesucc:"save successfullly",
  moneydanwei:'USD',
  welcome:'Welcome to @ shipping mail system',
  loginsucc:'Login success',
  birth_please:'yyyy-mm-dd',
  Name_of_new_goods:'Name of new goods',
  Chinese_name_of_goods:'Chinese name of goods',
  English_name_of_goods:'English name of goods',
  Abbreviation_of_goods_name:'Abbreviation of goods name',
  addgoods:'Goods name added successfully',
  matching:'matching',
  original:'original',
  detail:'Detail',
  delete:'delete',
  modify:'modify',
  searchbtn:'Search',
  reset:'Reset',
  edit:'Modify',
  submit:'submit',
  back:'back',
  save:'Save',
  telephonegeshi:'Format plus area code, such as 86-0000000000',
  isbangdingemail:'The email you entered has been registered. If you want to bind this email, please log off this email account before binding',
  isbangdingphone:'The mobile you entered has been registered. If you want to bind this mobile, please log off this mobile account before binding',
  accountnotactive:'The account is not activated. Please go to your registered mailbox to activate the account within 30 minutes',
  saveinformation:'Save Information',
  modifyinformation:'Modify Information',
  deleteinformation:'Delete Information',
  matchinformation:'Match Information',
  issue:'Issue',
  handle:'Handle',
  cancel:'Cancel',
  OK:'OK',
  header_login:'Login & Register',
  people:'personal',
  platform:'platform',
  vs:'Contrast',
  bangdingemail:'Supreme user, you need to bind the mailbox to publish information. Let\'s bind the mailbox',
  please_paste:'Please paste the original email',
  edit_message:'Do you want to save your modification？',
  vs_message:'Do you want to enter the match comparison page？',
  match_message:'Do you want to match this message？',
  check_message:'Do you want to view the details of this message？',
  delete_message:'Do you want to delete this message？',
  fullEname:'fullEname',
  previous:'Previous',
  next:'Next',
  collect:'Collect',
  files:'Files',
  check:'Check',
  moreinfo:'More Info',
  detailinfo:'Detailed Info',
  otherinfo:'Other information you may be interested in',
  withdays:'within days',
  generatereport:'Generate Report',
  VerificationCode:'CAPTCHA',
  getVerificationCode:'GetCAPTCHA',
  phonenumber:'Telephone',
  forgetPassword:'Forget Password',
  codelogin:"code login",
  secretlogin:"login password",
  errorpassword:'Incorrect password',
  backlogin:'back login',
  Mailbox_has_been:'Mailbox has been registered',
  phone_has_been:'Phone has been registered',
  Mailbox_has_been_registered:'Mailbox has been registered, if your account is not activated, please go to your registered email to activate the account',
  is_not_registered:'Mobile telephone number is not registered',
  Mailbox_has_not_register:'The email address is not registered, please register the account first',
  Phone_has_not_register:'The Telephone number is not registered, please register the account first',
  Cargo_matching:'Cargo matching',
  tct_matching:"Tct_matching",
  activate_your_account:'Please go to the email to activate your account',
  CAPTCHA_sent_successfully:'CAPTCHA sent successfully',
  Please_register:'Please register your account first',
  login:'Login',
  Codeloginfalse:'Incorrect CAPTCHA，please input again',
  Confirmpassword:'Confirm',
  regexpwd:'The password must contain both letters and numbers and at least 6 digits!',
  jihuoaccount:'Please go to the mailbox to activate your account within 30 minutes. If you do not activate your account within 30 minutes, the account will become invalid',
  Havenregistered:'Register an account',
  phoneregistered:'电话注册',
  comregistered:'邮箱注册',
  Register_Successful:'Register Successful',
  Submit:'Submit',
  loginemail:'Email Login',
  loginetel:'Telephone Login',
  accountnumber:'Cannot be a pure number',
  eamiloraccount:'email',
  buycheck:'Purchased, click to view',
  Tips:'Yips',
  isbuy:'You need to pay 1 point to check the sender\'s information. Do you want to pay?',
  gotobuy:'Your current points are insufficient. Please go to recharge',
  freechance:'You currently have a free opportunity. Do you want to use it?',
  nofoundmatch:'No matching results found',
  deletesucc:'Deleted successfully',
  editsucc:'Modified successfully',
  editfail:'Modified failed',
  all:'All',
  Records_consumption:'Records of Consumption',
  Recharge_record:'Recharge Record',
  date_please:'Please select the date',
  year_please:'Please select the year',
  startdatevalide:'Start time cannot be more than end time',
  enddatevalide:'End time cannot be less than start time',
  emailnull:'Please enter the email',
  emailerror:'Please enter the correct email address',
  checkandrefill:'Please check the unfilled input box',
  Incorrect:'Incomplete information',
  shipvesselmessage:'Please input the Vessel name',
  builtmessage:'Please input the built',
  shiptypemessage:'Please input the ship type',
  seaareamessage:'Please input the Sea area',
  portmessage:'Please input the Port',
  please_dwt:'Please input the dwt',
  please_danwei:'Please select dwt type',
  please_company:'Please input ship company',
  please_liner:'Please input liner',
  please_voyage:'Please input voyage',
  please_quantity:'Please input quantity',
  please_input:'Please input',
  maxmessage:'max cannot less than min ',
  minmessage:'min cannot more than max',
  shipage:"The maximum ship age is 50 years",
  onlyyear:'Only the data of recent year can be queried',
  years:'Years',
  day:'Days',
  collsucc:'collect successful',
  cancelcoll:'Cancel collection succeeded',
  password:{
    xiugai:'The temporary password has been sent to your email. Please log in as soon as possible and change the password in the personal Center - my information',
    getnewpass:"The new password has been sent to your mailbox. Please go to the mailbox to get login",
    mailnoregister:'Mailbox number is not registered',
    reset:'reset successfully',
    rehandel:'Please operate again according to the prompt',
    resetfail:'reset failed',
    resetpass:'reset password',
    Activation:'Activation succeeded',
    editpass:'The modification is successful. Please log in again with a new password',
    originalpass:'Original password input error, modification failed',
    editerror:'modification failed',
  },
  shipdate:{
    modifiedinfo:' Shipdate information modified successfully',
    releaseinfo:'Shipdate information released successfully',
    deletedinfo:'Shipdate information deleted successfully',
    getporttime:'Arrive Time',
    dwt:'DWT',
    name:'Voyage plan',
    vessel_name:'Vessel name',
    company_name:'Company',
    liner_cname:'Liner',
    linersea:'Start/Mid/End',
    voyage:'VoyageNumber',
    laycan:'Laycan',
    discharge_port_ename:'Dischargeport',
    loading_port_ename:'loadingPort',
    handling_capacity:'Crane',
    intent_cargo:'Intent cargo',
    voyage_time:'Sailing days',
    memo:'memo',
    sendTime:'sendTime',
    come:'Come',
    sender_time:'Release time',
    senderEmail:'Sender',
    addliner:"add a liner",
    sea_area:'Sea Area',
    linername:'Liner Name:',
  },

  //侧边栏
  sidebar:{
    issue:'Issue',
    search:'Search',
    tcmatch:'TC MATCHING',
    tctmatch:'TT MATCHING',
    dataanalysis:'Data analysis',
    spmatch:'Schedule Matching',
    estimate:'Voyage estimate',
    searchresult:'Search result',
    mytonnage:'My Tonnage',
    collectedtonnage:'Collected Tonnage',
    mycargo:'My Cargo',
    collectedcargo:'Collected Cargo',
    mytct:'My Tct',
    collectedtct:'Collected Tct',
    myshiptrade:'My ShipTrage',
    collshiptrade:'Collected ShipTrade',
    myvoyage:'My Voyageplan',
    collvoyage:'Collected Voyageplan',
    myestimate:'My Estimate',
    collestimate:'Collected Estimate',
    collttmatching:'Collected ttmatch',
    colltcmatching:'Collected tcmatch',
    shipdatematchcoll:'Collected shipdate',

    emailset:'Email setting',
    emailaccount:'Add account',
    emaildownload:'Email download',
    emailextract:'Email extract',
    emaillist:'Extract list',
    emailcargo:'Email cargo',
    emailtonnage:'Email Tonnage',
    emailtct:'Email tct',

    personalmessage:'Personal message',
    mymessage:'My message',
    myintegrate:'My points',
    mypurse:'My wallet',
    myissue:'My issue',
    mycollect:'My collection',
    searchhistory:'Retrieval history'
  },
  //导航栏
  navbar:{
    tonnage:'Tonnage',
    cargo:'Cargo',
    tct:'Tct',
    ship_trading:'Ship Trading',
    sailing_schedule:`Estimate`,
    email_extract:'Email Extract',
    personal_center:'Personal Center',
    dataanalysis:'Data Analysis',
    personal:'My infomation',
    login:'Login',
    register:'Register',
    welcome:'Welcome',
    logout:'Logout',
    ship_date:'Voyage plan'

  },
  mapbox:{
    essential_information:'essential information',
    detailed_information:'detailed information',
    laep:'Loading port / empty shipport',
    eadp:'Empty shipport / delivery port',
    dp:'delivery port',
  },
  //所有的查询组件
  search:{
    tonnagename:'TONNAGE',
    shipdatename:'shipdatename',
    vesselname:'Vessel',
    dwt:'DWT',
    maxdwt:'MAX DWT',
    min:'Min',
    max:'Max',
    dwttype:'Type',
    opendate:'openDate',
    startdate:'Start yyyy-mm-dd',
    enddate:'End yyyy-mm-dd',
    openarea:'openArea',
    seaarea:'sea area',
    openport:'port',
    vesselage:'VesselAge',
    issuetime:'Issue time',
    choose:'choose',
    cargoname:'CARGO',
    cargoName:'Cargo',
    quantity:'Quantity',
    maxquantity:'MAX Quantity',
    laycan:'Laycan',
    laycanstart:'start',
    laycanend:'end',
    loadingport:'loadingPort',
    dischargingport:'discharging'

  },
  addtonnage:{
    dwt:'DWT',
    name:'TONNAGE',
    shipname:'Vessel',
    build:'built',
    pickyear:'pick a year',
    opendate:'Open date',
    enddate:'End date',
    openarea:'Open area',
    intent_area:'Intent_area',
    intent_port:'Intent_port',
    intent_country:'Intent_country',
    intent_cargo:'Intent_cargo',
    sender:'Sender',
    sendtime:'Send_time',
    please_memo:'Memo',
    memo:'Memo',
    sszz:'Ready to load',
    shgz:'Inform later',
    tyzq:'Agree lease term',
    modify:'Modify',
    addtonnage:'Release',
    reset:'Reset'
  },
  ship:{
    bale:'Bale',
    loa:'Loa',
    beam:'Beam',
    depth:'Depth',
    callSign:'Call sign',
    deckNum:'Deck number',
    draft:'Draft',
    gear:'Gear',
    hatch:'Hatch',
    hold:'Hold',
    imo:'IMO',
    ship_class:'Ship class',
    nrt:'Nrt',
    pi:'P&I',
    type:'Type',
    tpc:'Tpc',
    flag:'Flag'
  },
  port: {
    airport: "Airport",
    anchorage: "Anchorage",
    approaches: "Approaches",
    berthingId: "Berthing",
    bulkCargo: "BulkCargo",
    cargoHandling: "CargoHandling",
    corporateOffice: "",
    country: "Country",
    cranes: "Cranes",
    freshWater: "FreshWater",
    fuel: "Fuel",
    including: "Including",
    latitude: "Latitude",
    location: "Location",
    longitude: "Longitude",
    medical: "Medical",
    overview: "Overview",
    pilotage: "Pilotage",
    pollution: "Pollution",
    portname: 'Port name',
    portEnameAbbr: "PortEnameAbbr",
    pratique: "Pratique",
    prearrival: "Prearrival",
    radarvts: "Radar/VTS",
    repairsDrydocks: "RepairsDrydocks",
    restrictions: "Restrictions",
    sewageDisposal: "SewageDisposal",
    stevedores: "Stevedores",
    tugs: "Tugs",
    vhf: "Vhf",
    wasteDisposal: "WasteDisposal",
    weatherTide: "WeatherTide",
    webSite: "WebSite"
  },
  tonnage:{
      modifiedinfo:' Tonnage information modified successfully',
      releaseinfo:'Tonnage information released successfully',
      deletedinfo:'Tonnage information deleted successfully',
      built:'Built',
      name:'TONNAGE',
      type:'Type',
      vesselName:'Vessel name',
      dwt:'Dwt',
      openArea:'openArea',
      openDate:'openDate',
      come:'Come',
      sender_time:'Release time',
      endDate:'End Date',
      openPort:'Open Port',
      vs:'Contrast',
      dataanalysis:'Tonnage Analysis',
    lalong:'Longtitude/latitude',
    seaarea:'Sea area',
    createdTime:'Created time'
  },
  cargo:{
    modifiedinfo:'Cargo information modified successfully',
    releaseinfo:'Cargo information released successfully',
    deletedinfo:'Cargo information deleted successfully',
    name:'CARGO',
    type:'Type',
    dataanalysis:'Cargo Analysis',
    vesselName:'Vessel Name',
    please_vesselName:'please input CargoName',
    dwt:'Dwt',
    please_dwt:'please input Dwt',
    openArea:'openArea',
    emptytime:'openDate',
    openDate:'Opendate',
    endDate:'Opendate',
    openPort:'Open Port',
    come:'Come',
    shorthandname:'Abbreviate',
    please_shorthandname:'please input Abbreviate',
    volume:'Quantity',
    please_volume:'please input Quantity',
    loading_port:'Loading',
    please_loading_port:'please input Loading port',
    dischargePort:'Discharging',
    please_dischargePort:'please input Discharging port',
    start_end_date:'Laycan',
    start_date:'start',
    please_start_date:'start',
    end_date:'end',
    please_end_date:'end',
    load_unload_rate:'L/D rate',
    loadrate:'Loading rate',
    dischargerate:'Discharging rate',
    please_load_rate:'please input Loading rate',
    please_unload_rate:'please input Unloading rate',
    commission:'ADDCOMM',
    please_commission:'please input addcomm',
    sender:'Sender',
    please_sender:'please input Sender',
    sendTime:'Send Time',
    please_sendTime:'please input Send Time',
    memo:'Memo',
    please_memo:'please input Other Requirements',
    modify:'Modify',
    release:'Release',
    reset:'Reset',
    issuetime:'Issue time',
    please_issuetime:'please input Issue time',
    search:'Search',
    card:'Card',
    list:'List',
    tonnage:'Tonnage',
    matching:'Matching',
    unit:'Unit',
    sszz:'Ready to load',
    shgz:'Inform later',
    tyzq:'Agree lease term',
    frt:'Frt',
    cargotype:'Cargo type',
    sf:'S.F',
    crane:'Crane',
    package:'Package',
    maxHeight:'MAX_H',
    maxWidth:'MAX_W',
    maxWeight:'MAX_WEIGHT',
    maxLength:'MAX_L',
    maxsize:'MAX_SIZE',
    transportMode:'Transport mode',
    currencyUnit:'Currency',
    term:'Term',
    loadrequest:'LoadRequest',
    cargoName:'Cargo',
    quantity:'Quantity',
    laycan:'Laycan',
    laycanstart:'laycanstart',
    laycanend:'laycanend',
    loadingport:'Loading',
    dischargingport:'Discharging',
    loadingfirstport:'Loading',
    loadingsecondport:'Loading',
    dischargingfirstport:'Discharge',
    dischargingsecondport:'Discharging',
    sender_time:'Release Time',
    opration:'Opration'
  },
  tct:{
    please_issuetime:'please input Issue time',
    modifiedinfo:' TCT information modified successfully',
    releaseinfo:'TCT information released successfully',
    deletedinfo:'TCT information deleted successfully',
    durationtype_please:'Please select duration type',
    issue:'Issue',
    tcmatching:'TT Matching',
    dataanalysis:'TCT Analysis',
    name:'TCT',
    tct:'TCT',
    account:'Account',
    vessel_type:'Vessel Type',
    delivery_area:'Delivery',
    redelivery_area:'Redelivery',
    delivery_port:'Delivery Port',
    redelivery_port:'Redelivery Port',
    sea_area:'Sea Area',
    port:'Port',
    laycan:'Laycan',
    intent_cargo:'Intent Cargo',
    duration:'Duration',
    addcomm:'Addcomm',
    age:'Age',
    max_age:'Max Age',
    memo:'Memo',
    vessel_name:'Vessel Name',
    dwt:'DWT',
    open_area:'openArea',
    open_date:'OpenDate',
    sender:'Sender',
    send_time:'Send Time',
    release:'Release',
    search:'Search',
    reset:'Reset',
    matching:'Matching',
    please_select:'Please select',
    start_date:'Start Date',
    end_date:'End Date',
    enter_accountname:'The name of the account',
    decknum:'DeckNumber',
    hatchnumber:'HatchNumber',
    holdnumber:'HoldNumber',
    crane:'Crane',
    grain:'Grain',
    come:'Come',
    sender_time:'Release time'
  },
  shiptrade:{
    modifiedinfo:'Shiptrade information modified successfully',
    releaseinfo:'Shiptrade information released successfully',
    deletedinfo:'Shiptrade information deleted successfully',
    name:'SHIP TRADING',
    built:'Built',
    type:'Type',
    vessel_name:'Vessel',
    dwt:'DWT',
    IMO:'IMO',
    releasing_area:'Delivery Area',
    releasing_time:'Delivery Time',
    come:'Come',
    sender_time:'Release time',
    broker:'Broker',
    owner:'Owner',
    recorder:'Recorder',
    record_time:'Record Time',
    memo:'Memo',
    releasetime_input:'release time',
    releasearea_input:'release area',
    select:'select',
    dataanalysis:'Ship Trade Analysis',
    rules:{
      rvessel_name:'vessel_name',
      rdwt:'dwt',
      rbuilt:'built_time',
      rtype:'type',
      rreleasing_time_start:'releasing_time_start',
      rreleasing_time_end:'releasing_time_end',
      rreleasing_area:'releasing_area',
      rreleasing_port:'releasing_port',
    }
  },
  estimate:{
    four:'Cargoname\u3000\u3000\u3000\u3000\u3000\u3000\u3000Quantity\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000L/D Port\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000Feight',
    cargoname:'Cargoname',
    quantity:'Quantity',
    ldport:'L/D Port',
    feight:'Feight',
    collect:'Collected',
    vesselname:'Vessel',
    Handel1:'Handel',
    voyage_num:'Voyage',
    loss:'Loss',
    totalcost:'Total Cost',
    voyage:{
      successadd:'Voyage estimation information added successfully',
      goonfill:'Please continue to fill in the information!',
      name:'Voyage Infor',
      vesselname:'Vessel',
      voyage:'Voyage',
      dwt:'Dwt',
      cbm:'Cabin Capacity',
      knot:'Speed'
    },
    ship:{
      name:'Ship Status',
      shipstatus:'Loading Status',
      port:'Port',
      cargoname:'Cargoname',
      quantity:'Quantity',
      feight:'Feight',
      feighttax:'Feight Tax',
      portfees:'Portfees',
      discountcomm:'Addcomm',
      brokercomm:'Brokerage',
      others:'Other Costs',
      distance:'Cruding Range',
      seadays:'Sailing Days',
      idledays:'Idle Days',
      workdays:'Work Days',
      accuratetominutes:'Accurate to Minutes',
      addindown:'Add Blow'
    },
    cargo:{
      name:'Cargo Infor',
      loadingport:'Loadingport',
      dischargeport:' Dischargeport',
      dataanalysis:'Cargo Analysis',
    },
    person:{
      mytonnage:'My Tonnage',
      collectedtonnage:'Collected Tonnage',
      mycargo:'My Cargo',
      collectedcargo:'Collected Cargo',
      mytct:'My Tct',
      collectedtct:'Collected Tct',
    },
    fuel:{
      name:'Fuel Consumption',
      fueltype:'Fuel Type',
      sailballast:'Light Navigation',
      heavysail:'Heavy Navigation',
      waitport:'Wait for Port',
      work:'Work',
      fuelprice:'Fuel Price'
    },
    rent:{
      name:'Ship Rent（$）',
      rentperday:'Rent Perday',
      commission:'commission',
      Equivalentrentlevel:'Rent Level'
    },
    otherfee:{
      name:'Other Fee（$）',
      Demurrage:'Demurrage Fees',
      dispatchfee:'Dispatch Fees',
      subsidy:'Subsidies',
      warehousesweepfee:'Clearance Fee'
    },
    result:{
      name:'Voyage Calculation Result',
      totaldistance:'Total Range Distance',
      totaldays:'Total Sailing Time',
      totalseadays:'Total Time in Port',
      allvoyagetime:'Total Voyage Time',
      firsteta:'Estimated departure Time',
      lastetd:'Estimated arrival Time'
    },
    allcost:{
      name:'Total Cost（$）',
      totalfuel:'Fuel Cost',
      totalportfee:'Portfees',
      addresscomm:'Addcomm',
      brokercomm:'Brokerage',
      rent:'Rent',
      rencomm:'Commission',
      tontax:'Tontax',
      tontax_:'Tontax=',
      othercost:'Other Cost',
      totalcost:'Total Cost'
    },
    budget:{
      name:'Profit（$）',
      freightrevenue:'Revenue',
      loss:'Loss',
      profit:'Profit',
      profitper:'Net Income',
      profitperday:'Daily Net Income',
    },
    danwei:{
      days:'days'
    }
  },
  personcenter:{
    pasword:'Numbers and letters at least 8 digits',
    personalcenter:'Personal Center',
    username:'User Name',
    password:'Password',
    name:'Name',
    mailbox:'Mailbox',
    qq:'QQ',
    twitter:'Twitter',
    birth:'Birth',
    accountduty:'Accountduty',
    skype:'Skype',
    facebook:'Facebook',
    whatsapp:'Whatsapp',
    department:'Department',
    phonenumber:'PhoneNumber',
    please_phone:'Please input phone number',
    please_rphone:'Please input right phone number',
    modifyinfor:'Modify Information',
    cancel:'Cancel',
    accountinfor:'Account Information',
    amountnumber:'Amount/Number',
    recharge:'Recharge',
    top_upamount:'Top-up Amount',
    walletamount:'Wallet Amount',
    pointsnumber:'Points Number',
    comfirmclose:'Confirm closing?',
    customized:'Customized',
    paymentway:'Payment Way',
    gotopay:'Go to pay',
    wechat:'Wechat',
    alipay:'Alipay',
    wechatpay:'wechat payment',
    alipaypay:'Alipay payment',
    yuepay:'Balance payment succeeded',
    bankcard:'Bank Card',
    purse:'Purse',
    please_account:'please input account',
    please_number:'please input number',
    retirevinghistory:'Retrieval History',
    inforcategory:'Category',
    all:'All',
    tonnage:'Tonnage',
    cargo:'Cargo',
    tct:'Tct',
    keyword:'Keyword',
    inquire:'Inquire',
    resultlist:'Results List',
    retrievecondition:'Retrieval Condition',
    result:'Result',
    retrievetime:'Retrieval Time',
    delete:'Delete',
    viewperson:'View Personal Message',
    account:'Account',
    sex:'sex',
    changepassword:'Change password',
    originalpwd:'Original password',
    newpwd:'New password',
    please_oldpwd:'Please input original password',
    please_newpwd:'please reset new password',
    confirmnewpwd:'Confirm password',
    newpwdnoold:'The new password cannot be the same as the original password',
    twicepwd:'The password entered twice are inconsistent!',
    jifenyue:'Integral balance',
    jifenmingxi:'Integral details',
    jifenjilu:'Integral record',
    time:'Time',
    type:'Type',
    jifenconsumer:'Consume count',
    jifenearn:'Integral count',
    consumerlist:'Records of consumption',
    receiverlist:'Records of earn',
    consumeinteger:'Consume Integral',
    getinteger:'Get Integral',
    memo:'memo',
    qianbaoyue:'Wallet balance',
    walletrecharge:'Wallet Recharge',
    integralrecharge:'Integral Recharge',
    yuan:'Yuan',
    qianbaomingxi:'Wallet details',
    qianbaojilu:'Wallet record',
    jine:'Money amount',
    man:'man',
    woman:'woman',
  },
  email:{
    mailconnect:'Mailbox connection succeeded',
    accountadd:'Account mailbox added successfully',
    account:'Account',
    mailbox:'Mailbox',
    maildownload:'Mail Download',
    mailsettings:'Mail Settings',
    accountdelete:'Delete account',
    emailoriginal:'check original email',
    emailoriginalcontent:'original content',
    email_content:'content',
    modify:'modify information',
    please_email:'Please fill in the content correctly',
    please_input:'Please paste the original email',
    currentaccount:'Email account',
    extractemail:'Mail extraction',
    seletemail:'extract from email',
    accountlist:' accountlist',
    emaildownload:'emaildownload',
    downloadmailsetting:'Download Mail Setting',
    date:'date',
    mailform:'Mail server type',
    reset:'reset',
    DownloadProgressMeter:'Download Message',
    Downloadtips:'Downloadtips',
    stop:'stop',
    MailType:'ChoseMailType',
    mailtheme:'theme',
    sender:'sender',
    recipient:'recipient',
    sendtime:'sendtime',
    starttime:'startTime',
    endtime:'endTime',
    ExtractProgressMeter:'ExtractProgressMeter',
    ExtractTips:'ExtractTips',
    extract:'extract',
    clear:'clear',
    timeframe:'time frame',
    DefaultEmailAddress:'default email address',
    retrieval:'retrieval',
    lastpage:'lastpage',
    nextpage:'nextpage',
    cancel:'cancel',
    ok:'ok',
    modifyinformation:'modify information',
    matchinformation:'match information',
    deleteinformation:'Delete information',
    mailservertype:'Mail Server Type',
    mailsetting:'mailsetting',
    Internetmailsetting:'Internet mail Setting',
    addaccount:'addaccount',
    userinformation:'User information',
    accountname:"Account name:",
    mailaddress:"Mail address:",
    serverinformation:'Server information',
    accounttype:'Account Type:',
    UserID:'User ID:',
    Logininformation:'Login information(Mail Server)',
    Password:'Password:',
    ServerPortinformation:'Server Port information',
    IncomingServer:'Incoming Server',
    Usedefault:'Use default',
    ServerTimeoutSetting:'Set server connection timeout',
    min:'Min',
    AccountTesting:'Account Testing',
    TestAccountSetting:'Test Account Setting',
    finish:'finish',
    IncomingMailServer:'Reseive Mail Server:',
    sentence1:'Do you want to save your modification?',
    sentence2:'Do you want to match this message?',
    sentence3:'Do you want to delete this message?',
    sentence4:'Please input information for each settings correct to use your mail account working.',
    sentence5:'Encrypted connection needed(SSL)',
    sentence6:'After inputting the information on this screen,you can test your account by click the button below.It requires network connection.',
  },
  file:{
    selectfile:'select file',
    supportfile:"Only documents or pictures are supported, and the size is no more than 2M",
    filesucc:'File submitted successfully',
    filefail:'File submission failed',
    file_size:'Upload file size not more than 2M',
    downsucc:'Download successfully',
  }
}


  export default en;
