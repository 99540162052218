import request from '/src/utils/request'

export function getCode() {
  return request({
    url: '/api/getCode',
    method: 'post'
  })
}
//获取前端菜单
export function getMenu(ID) {
  return request({
    url: '/api/menu/get/'+ID,
    method: 'post'
  })
}
//权限接口
export function getAccess() {
  return request({
    url: '/api/permission/findMy',
    method: 'get'
  })
}

export function getSea(Id) {
  return request({
    url: '/api/measserver/port/getSeaarealocal/'+Id,
    method: 'post'
  })
}
export function getRSAPK() {
  return request({
    url: '/api/permission/getRSAPK',
    method: 'get'
  })
}

