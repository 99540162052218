import { createStore } from "vuex";
import fold from './modules/fold'
import app from './modules/app'
import getters from "./getters";
import  user from './modules/user'
import metadata from './modules/metadata'
import global from './modules/global'

export default createStore({
  modules: {
    fold,
    app,
    user,
    metadata,
    global
  },
  getters
});
