import router from './router'
import store from './store'
import { ElMessage } from 'element-plus'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken , setUserName } from '@/utils/auth' // get token from cookie
//import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/auth-redirect','/password','/register','/resetpassword','/resetFail','/resetSuccess','/jihuo'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()
  // set page title
  //document.title = getPageTitle(to.meta.title)

  // determine whether the user has logged in
  const hasToken = getToken()

  if (hasToken) {
  //  console.log(hasToken)
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done()
    } else {
      // determine whether the user has obtained his permission roles through getInfo
      const hasRoles = store.getters.roles && store.getters.roles.length > 0
      if (hasRoles) {
        next()
      } else {
        try {
          // get user info
          // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
          // const { roles , name } = await store.dispatch('user/getInfo')
          // setUserName(name)
          // 加载菜单 add by dugf 2019.4.16
          await store.dispatch('global/getMenu')
          next()
          //next({ ...to, replace: true })
          // generate accessible routes map based on roles 过滤路由
          //const accessRoutes = await store.dispatch('permission/generateRoutes', roles)

          // let currentRole = []
          // roles.forEach( item => {
          //   if(item !== '32') {
          //     currentRole.push(item)
          //   }
          // })
          // //console.log("🚀 => file: permission.js => line 45 => router.beforeEach => currentRole", currentRole)
          // if(!currentRole.length) {
          //
          //   await store.dispatch('user/resetToken')
          //   Message.error('无登录权限')
          //   next(`/login?redirect=${to.path}`)
          //   NProgress.done()
          // } else {
          //   // dynamically add accessible routes
          //   router.addRoutes(accessRoutes)
          //
          //   // hack method to ensure that addRoutes is complete
          //   // set the replace: true, so the navigation will not leave a history record
          //   next({ ...to, replace: true })
          // }
          
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch('user/resetToken')
          Message.error(error || 'Has Error')
          next(`/login?redirect=${to.path}`)
          NProgress.done()
        }
      }
    }
  } else {
    /* has no token*/

    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
