import { getCode, getMenu } from '../../../src/api/global'
import {getPermission} from "../../utils/auth";

const state = {
  dict: {},
  menu:[],
  permission:getPermission()
}
const mutations = {
  SET_DICT: (state, dict) => {
    state.dict = dict
  },
  SET_MENU: (state, menu) => {
    state.menu = menu
   // console.log(state.menu)
  },
  SET_PERMISSION:(state,permission)=>{
    state.permission = permission
  }
}

const actions = {
  // init codes
  getCode({ commit }) {
    return new Promise((resolve, reject) => {
      //getCode().then(response => {
        const { data } = response
        commit('SET_DICT', data.dict)
        resolve()
      }).catch(error => {
        reject(error)
      })
   },
  // init codes
  getMenu({ commit }) {
   // console.log("循环")
    return new Promise((resolve, reject) => {
      getMenu('2').then(response => {
        var mapData = response.data.map((item) => {
          const data = {
            path: `/${item.path}`,
            hidden: item.hidden,
            id: item.id,
            redirect: 'noRedirect',
            meta: {
              title: item.label,
              icon: item.icon,
              affix: false
            }
          }
          if (item.children) {
             //console.log(item.children)
             data.children = item.children.map((val) => {
              const childrendata = {
                path: `${val.path}`,
                hidden: val.hidden,
                id: val.id,
                meta: {
                  title: val.label,
                  icon: val.icon,
                  affix: false
                }
              }
              if (val.children) {
                childrendata.children = val.children.map((i) => {
                  return {
                    path: `${i.path}`,
                    hidden: i.hidden,
                    id: i.id,
                    meta: {
                      title: i.label,
                      icon: i.icon,
                      affix: false

                    }
                  }

                })
              }
              return childrendata
            })
          }

          return data
        })
       // console.log('generate menu', mapData)
        commit('SET_MENU', mapData)
        resolve()
      }).catch(error => {
        reject(error)
      })
    //})
  })
  },
  removeMenu({commit}){
    commit('SET_MENU',[])

  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
