import axios from 'axios'
import store from '../store'
import router from '../router/index'
import { getToken, removeToken,getSercet } from './auth'
import {  ElMessage } from 'element-plus'

var msg = null
// create an axios instance
const service = axios.create({
  baseURL: '/', // url = base url + request url
  // baseURL: 'http://localhost:8887/', // url = base url + request url
  // baseURL: 'http://lhsco.imwork.net/', // url = base url + request url

  withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['X-Token'] = getToken()
      config.headers['Authorization'] = getToken()
      if(config.url == '/api/accountinfo/save-secret' ||config.url =='/api/accountinfo/getDetil-secret'){
        config.headers['secret_key'] = getSercet()
      }
      // config.headers['Access-Control-Allow-Origin'] = '*'
      // config.headers['Access-Control-Allow-Headers'] = 'Content-Type, Authorization, X-Requested-With'
      // config.headers['Access-Control-Allow-Methods'] = 'PUT,POST,GET,DELETE,OPTIONS'
    }
    return config
  },
  error => {
    // do something with request error
   // console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
   // console.log(res)
    // if the custom code is not 20000, it is judged as an error.
    // if (res.error_code != 200) {
    //   // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    //   if (res.error_code == 401 || res.status == 401) {
    //     removeToken()
    //     store.commit('user/SET_LOGINSTATUS', false)
    //     store.dispatch('user/resetToken');
    //     setTimeout(() => {
    //       // location.reload()
    //       router.push("/" );
    //     }, 2000)
    //   }else {
    //     if(msg){
    //       msg.close()
    //     }
    //     if([401,300].indexOf(res.error_code)>-1){
    //     /*  msg = ElMessage({
    //         message: res.error_msg,
    //         type: 'error',
    //       })*/
    //     }
    //     else if([201,403,400].indexOf(res.error_code)>-1){
    //      /* msg = ElMessage({
    //         message: res.error_msg,
    //         type: 'warning',
    //       })*/
    //     }
    //       return res
    //   }
    //   // return Promise.reject(res.message || 'error')
    // } else {
    //   return res
    // }

    //拦截器里面不对状态码进行判断，在vue页面中对各种状态进行判断
    return res
  },
  error => {
   // console.log('err' + error) // for debug
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

export default service
