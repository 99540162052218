import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getUserName() {
  return Cookies.get('username')
}

export function setUserName(username) {
  return Cookies.set('username', username)
}

export function getEnterCMS() {
  return Cookies.get('enterCMS')
}

export function setEnterCMS(enterCMS) {
  return Cookies.set('enterCMS', enterCMS)
}

export function setSercet(sercet){
  return Cookies.set('sercet',sercet)
}
export function getSercet(){
  return Cookies.get('sercet')
}

export function setPermission(permission){
  return Cookies.set('permission',permission)
}
export function getPermission(){
  return Cookies.get('permission')
}
