import request from '/src/utils/request'

export function collectTonnageTCT(param) {
  return request({
    url: `/api/match/selectTonnageTct/save`,
    method: 'post',
    data: param
  })
}
export function canclecollectTonnageTCT(param) {
  return request({
    url: `/api/match/selectTonnageTct/delete`,
    method: 'post',
    data:param
  })
}
export function getEmailDetail(param) {
  return request({
    url: `/tonnage/tonnage/getEmailDetail`,
    method: 'get',
    data: param
  })
}
export function matchCargo(param) {
  return request({
    url: `/api/match/getTonnageCargoList`,
    method: 'post',
    data: param
  })
}
export function collmatchCargo(param) {
  return request({
    url: `/api/match/selectTonnageCargo/getcard`,
    method: 'post',
    data: param
  })
}
export function searchmatchCargo(param) {
  return request({
    url: `/tonnage/tonnage/matchCargo`,
    method: 'post',
    data: param
  })
}
export function matchTCT(param) {
  return request({
    url: `/api/match/getTonnageTctList`,
    method: 'post',
    data: param
  })
}export function selectcollTCT(param) {
  return request({
    url: `/api/match/selectTonnageTct/getcard`,
    method: 'post',
    data: param
  })
}
export function searchmatchTCT(param) {
  return request({
    url: `/tonnage/tonnage/matchTCT`,
    method: 'post',
    data: param
  })
}
export function collectTonnageCargo(param) {
  return request({
    url: `/api/match/selectTonnageCargo/save`,
    method: 'post',
    data: param
  })
}
export function cancelCollectTonnageCargo(param) {
  return request({
    url: `/api/match/selectTonnageCargo/delete`,
    method: 'post',
    data:param
  })
}
export function updateTonnage(param) {
 // console.log(param)
  return request({
    url: `/api/tonnage/save`,
    method: 'post',
    data: param
  })
}
export function deleteTonnage(param) {
  return request({
    url: `/api/tonnage/delete`,
    method: 'post',
    data:param
  })
}
//my, page, size
export function searchUserTonnage(param) {
  return request({
    url: `/api/tonnage/getmycard`,
    method: 'post',
    data: param
  })
}
//查找用户发布的tonnage
export function searchTonnage(param) {
  return request({
    url: `/api/tonnage/getcard`,
    method: 'post',
    data: param
  })
}

//根据tonnageid查找tonnage信息（当前用户发布）
export  function searchTonnagebyId(param) {
 // console.log(param)
  return request({
    url:`/api/tonnage/getdetil/${param}`,
    method:'post'
  })
}
// jx add 根据海域名称查找海域ID
export  function searchAreabyName(param) {
  // console.log(param)
   return request({
     url:`/api/tonnage/getSeaAreaId/${param}`,
     method:'post'
   })
 }
 // jx add 根据港口名称查找港口ID
 export  function searchPortbyName(param) {
  // console.log(param)
   return request({
     url:`/api/tonnage/getSeaPortId/${param}`,
     method:'post'
   })
 }

export  function searchTonnagepayed(param) {
 // console.log(param);
    return request({
    url:`/api/tonnage/getpaydetil`,
    method:'post',
    data:param
  })
}


//检索收藏船舶动态信息
export function searchcollecttonnage(param) {
  //console.log(param)
  return request({
    url: `/api/tonnage/selectTonnage/getcard`,
    method: 'post',
    data: param
  })
}

//收藏船舶动态信息
export function collect(param) {
//  console.log(param)
  return request({
    url: `/api/tonnage/selectTonnage/save`,
    method: 'post',
    data: param
  })
}
//取消收藏船舶动态信息
export function cancelCollect(param) {
  return request({
    url: `/api/tonnage/selectTonnage/delete`,
    method: 'post',
    data:param
  })
}
export function addTonnage(param) {
  return request({
    url: `/api/tonnage/save`,
    method: 'post',
    data: param
  })
}
//邮件抽取获取tonnage列表
export function getEmailList() {
  return request({
    url: `/api/email_parse_tonnage/getList`,
    method: 'get'
  })
}
//上传文件
export function selectDocument(param) {
  return request({
    url: `/api/tonnage/accessoryUpload`,
    method: 'post',
    data:param
  })
}
//文件列表
export function getFilelist(param) {
  return request({
    url: `/api/tonnage/accessory/getListById`,
    method: 'post',
    data:param
  })
}
//图片下载
export function downloadDocument(params) {
  return request({
    url: `/api/tonnage/accessory/downloadFile`,
    method: 'post',
    data:params,
    responseType: "arraybuffer"
  })
}
//pdf下载
export function downloadPdf(params) {
  return request({
    url: `/api/tonnage/accessory/downloadFile`,
    method: 'post',
    data:params,
    responseType: "blob"
  })
}
//删除文件
export function deletetonnageDocument(params) {
  return request({
    url: `/api/tonnage/accessory/userDeleteFile`,
    method: 'post',
    data:params
  })
}

//获得bashPath
export function getbasePath() {
  return request({
    url: `/api/tonnage/getPath`,
    method: 'post'
  })
}
//其他模块附件上传
export function otherDocument() {
  return request({
    url: `/api/tonnage/outUpload`,
    method: 'post'
  })
}
export function queryTonnage(param){
  return request({
    url: `/api/tonnage/getAnalysis`,
    method: 'post',
    data: param
  })
}
