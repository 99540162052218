const zn = {
  home:'首 页',
  card:'卡 片',
  list:'列 表',
  T:'吨',
  m:'米',
  a:'个',
  Cbm:'Cbm',
  moneydanwei:'RMB',
  welcome:'欢迎使用 @航运邮件系统',
  loginsucc:'登录成功',
  birth_please:'yyyy-mm-dd',
  Name_of_new_goods:'新增货物名称',
  Chinese_name_of_goods:'货物中文名称',
  English_name_of_goods:'货物英文名称',
  Abbreviation_of_goods_name:'货物名称简写',
  addgoods:'货名添加成功',
  telephonegeshi:'格式加区号，如86-00000000000',
  matching:'匹 配',
  original:'原邮件',
  detail:'查看详情',
  delete:'删 除',
  modify:'修 改',
  searchbtn:'搜 索',
  reset:'重 置',
  edit:'修 改',
  submit:'提交',
  back:'返回',
  save:'保存',
  tempsave:'暂存至本地',
  savesucc:"保存成功",
  isbangdingemail:'您输入的邮箱已被注册，如要绑定此邮箱，请注销此邮箱账号后再绑定。',
  isbangdingphone:'您输入的电话号已被注册，如要绑定此电话号，请注销此电话账号后再绑定。',
  accountnotactive:'激活邮箱已发送，请在30分钟内前往您注册的邮箱中激活账号',
  accountnotactiv:'请在30分钟内前往您注册的邮箱中激活账号，如已超过30分钟，请用邮箱账号登录重新发送激活邮箱',
  saveinformation:'保存信息',
  modifyinformation:'修改信息',
  deleteinformation:'删除信息',
  matchinformation:'匹配信息',
  issue:'发 布',
  handle:'操作',
  cancel:'取 消',
  OK:'确 定',
  header_login:'登录/注册',
  people:'个人',
  platform:'平台',
  vs:'匹配对比',
  bangdingemail:'尊敬的用户，您需要绑定邮箱才可以发布信息，来绑定邮箱吧',
  please_paste:'请粘贴原邮件',
  edit_message:'您想要保存您的修改吗？',
  vs_message:'您想要进入匹配对比页面吗？',
  match_message:'您想要匹配该条信息吗？',
  check_message:'您想要查看该条信息的具体内容吗？',
  delete_message:'您想要删除该条信息吗？',
  fullEname:'名称',
  previous:'上一页',
  next:'下一页',
  collect:'收藏',
  files:'文件列表',
  check:'查 看',
  moreinfo:'更多信息',
  withdays:'天以内',
  generatereport:'生成报表',
  VerificationCode:'验证码',
  getVerificationCode:'获取验证码',
  phonenumber:'手机号',
  forgetPassword:'忘记密码',
  codelogin:"验证码登录",
  secretlogin:"密码登录",
  login:'登 录',
  backlogin:'返回登录页面',
  Codeloginfalse:'验证码错误，请重新填入',
  Confirmpassword:'确认密码',
  errorpassword:'密码不正确',
  Mailbox_has_been:'邮箱已被注册',
  phone_has_been:'手机号已被注册',
  Mailbox_has_been_registered:'邮箱已被注册,如未激活,请尽快前往邮箱中激活账号',
  is_not_registered:'手机号未注册',
  Mailbox_has_not_register:'邮箱未注册，请先注册账号',
  Phone_has_not_register:'手机号未注册，请先注册账号',
  Cargo_matching:'船货匹配',
  tct_matching:"租船匹配",
  activate_your_account:'请前往邮箱中激活账号',
  Please_register:'请先注册账号',
  CAPTCHA_sent_successfully:'验证码发送成功',
  Havenregistered:'注册账号',
  phoneregistered:'电话注册',
  comregistered:'邮箱注册',
  Register_Successful:'注册成功',
  regexpwd:'密码须同时包含字母和数字且至少6位',
  jihuoaccount:'请在30分钟内前往邮箱中激活账号，如超过时间未激活账号会失效',
  Submit:'提 交',
  loginemail:'邮箱登录',
  loginetel:'手机号登录',
  accountnumber:'不能设置为纯数字',
  eamiloraccount:'邮箱',
  buycheck:'已购买，点击查看',
  Tips:'提示',
  isbuy:'你需要支付1积分去查看发件人信息，是否支付?',
  gotobuy:'您当前积分不足，请前往充值',
  freechance:'您当前有一次免费的机会，是否使用？',
  nofoundmatch:'找不到匹配结果',
  deletesucc:'删除成功',
  editsucc:'修改成功',
  editfail:'修改失败，请联系管理员修改。',
  all:'全部',
  Records_consumption:'消费记录',
  Recharge_record:'充值记录',
  date_please:'请选择日期',
  year_please:'请选择年份',
  startdatevalide:'开始日期不能晚于结束日期',
  enddatevalide:'结束日期不能早于开始日期',
  emailnull:'请输入邮箱',
  emailerror:'请输入正确的邮箱地址',
  checkandrefill:'请检查未填输入框填写',
  Incorrect:'信息填写不完整',
  shipvesselmessage:'请输入船名',
  builtmessage:'请输入建造日期',
  shiptypemessage:'请输入船型',
  seaareamessage:'请输入海域',
  portmessage:'请输入港口',
  please_dwt:'请输入载重吨',
  please_danwei:'请选择单位',
  please_company:'请输入船公司',
  please_liner:'请输入航线',
  please_voyage:'请输入航次',
  please_quantity:'请输入货量',
  please_input:'请输入',
  maxmessage:'最大值不能小于最小值',
  minmessage:'最小值不能大于最大值',
  shipage:"最大船龄为50年",
  onlyyear:'只能查询近一年数据',
  years:'年',
  day:'天',
  detailinfo:'详细信息',
  otherinfo:'其他您可能感兴趣的信息',
  collsucc:'收藏成功',
  cancelcoll:'取消收藏成功',
  password:{
    xiugai:'临时密码已发送到您的邮箱，请尽快登录，在个人中心-我的信息-修改密码',
    getnewpass:"新的密码已发送到您的邮箱,请前往邮箱中获取登录",
    mailnoregister:'邮箱号未注册',
    reset:'重置成功',
    rehandel:'请根据提示重新操作',
    resetfail:'重置失败',
    resetpass:'重置密码',
    Activation:'激活成功',
    editpass:'修改成功，请用新密码重新登录',
    originalpass:'原密码输入错误，修改失败',
    editerror:'修改失败',
  },
  sidebar:{
    issue:'发布',
    search:'搜索',
    tcmatch:'船货匹配',
    tctmatch:'租船匹配',
    dataanalysis:'数据分析',
    spmatch:'航期匹配',
    mytonnage:'我的船盘',
    collectedtonnage:'收藏的船盘',
    mycargo:'我的货盘',
    collectedcargo:'收藏的货盘',
    mytct:'我的租船',
    collectedtct:'收藏的租船',
    myshiptrade:'我的船舶贸易',
    collshiptrade:'收藏的船舶贸易',
    myvoyage:'我的航次计划',
    collvoyage:'收藏的航次计划',
    myestimate:'我的航次估算',
    collestimate:'收藏的航次估算',
    collttmatching:'收藏的租船匹配',
    colltcmatching:'收藏的船货匹配',
    shipdatematchcoll:'收藏的航期匹配',


    estimate:'航程计算',
    searchresult:'结果查询',

    emailset:'邮件设置',
    emailaccount:'添加账户',
    emaildownload:'邮件下载',
    emailextract:'AI识别',
    emaillist:'AI识别内容列表',
    emailcargo:'货盘信息识别列表',
    emailtonnage:'船舶动态识别列表',
    emailtct:'租船信息识别列表',

    personalmessage:'个人信息',
    mymessage:'我的信息',
    myintegrate:'我的积分',
    mypurse:'我的钱包',
    myissue:'我的发布',
    mycollect:'我的收藏',
    searchhistory:'检索历史',

  },
  navbar:{
    tonnage:'船舶动态',
    cargo:'货盘',
    tct:'租船',
    ship_trading:'船舶贸易',
    sailing_schedule:'航次估算',
    email_extract:'AI识别',
    personal_center:'个人中心',
    dataanalysis:'数据分析',
    personal:'我的',
    login:'登录',
    register:'注册',
    welcome:'欢迎您',
    logout:'退出',
    ship_date:'航次计划'

  },
  mapbox:{
    essential_information:'基本信息',
    detailed_information:'详细信息',
    laep:'装货港/空船港口',
    eadp:'空船港口/交船港口',
    dp:'交船港口',
  },
  search:{
    tonnagename:'船舶动态信息',
    shipdatename:'船期信息',
    vesselname:'船名',
    dwt:'载重吨',
    maxdwt:'最大载重吨',
    min:'最小值',
    max:'最大值',
    dwttype:'类型',
    opendate:'空船时间',
    startdate:'开始 yyyy-mm-dd',
    enddate:'结束 yyyy-mm-dd',
    openarea:'空船海域',
    seaarea:'海域',
    openport:'港口',
    vesselage:'船龄',
    issuetime:'发布时间',
    choose:'请选择',
    cargoName:'货名',
    cargoname:'货盘信息',
    quantity:'货量',
    maxquantity:'最大货量',
    laycan:'受载期',
    laycanstart:'开始',
    laycanend:'结束',
    loadingport:'装货港',
    dischargingport:'卸货港'

  },
  shipdate:{
    modifiedinfo:'船期信息修改成功',
    releaseinfo:'船期信息发布成功',
    deletedinfo:'船期信息删除成功',
    getporttime:'到港时间',
    dwt:'载重吨',
    name:'航次计划',
    vessel_name:'船名',
    company_name:'船公司',
    liner_cname:'航线',
    linersea:'起始海域/途径海域/终点海域',
    voyage:'航次',
    laycan:'受载期',
    discharge_port_ename:'卸货港',
    loading_port_ename:'装货港',
    handling_capacity:'装卸能力',
    intent_cargo:'意向货物',
    voyage_time:'航行天数',
    memo:'其他信息',
    sendTime:'发布时间',
    come:'来源',
    sender_time:'发布时间',
    senderEmail:'发布人',
    addliner:"添加航线",
    sea_area:'海域',
    linername:'航线名称:',

  },
    addtonnage:{
      dwt:'载重吨',
      name:'船舶动态信息',
      shipname:'船名',
      build:'建造日期',
      pickyear:'选择年份',
      opendate:'空船时间',
      openarea:'空船海域',
      intent_area:'意向海域',
      intent_port:'意向港口',
      intent_country:'意向国家',
      intent_cargo:'意向货物',
      sender:'发布人',
      sendtime:'发布时间',
      please_memo:'请输入其他要求',
      starttime:'开始时间',
      endtime:'结束时间',
      memo:'其他要求',
      sszz:'随时装载',
      shgz:'随后告知',
      tyzq:'同意租期',
      modify:'修改信息',
      addtonnage:'发布信息',
      reset:'重置'
    },
  ship:{
    bale:'袋装舱容（立方米）',
    loa:'船长',
    beam:'船宽',
    depth:'船深',
    callSign:'呼号',
    deckNum:'甲板数',
    draft:'吃水',
    gear:'船吊',
    hatch:'舱盖数量',
    hold:'货舱数量',
    imo:'IMO',
    ship_class:'船级',
    nrt:'净吨',
    pi:'互保协会',
    type:'船型',
    tpc:'每厘米吃水',
    flag:'船旗'
  },
  port:{
    longlatitude:'经/纬度',
    airport:"机场信息",
    anchorage:"锚地",
    approaches:"进港路线",
    cargoHandling:"港口装卸能力",
    country:"国家",
    cranes:"吊具",
    latitude:"维度",
    location:"位置",
    longitude:"经度",
    portname:'港口名',
    portEnameAbbr:"港口简称",
    overview:"港口简介",
    including:"附属地",

    berthingId:"Berthing",
    bulkCargo:"散装货物",
    corporateOffice:"",
    freshWater:"是否提供淡水",
    fuel:"是否提供燃油",
    medical:"医疗",
    pilotage:"引航",
    pollution:"污染",
    pratique:"通行证",
    prearrival:"到达前通知",
    radarvts:"雷达/VTS",
    repairsDrydocks:"维修/干船坞",
    restrictions:"港口限制",
    sewageDisposal:"污水处理",
    stevedores:"装卸工",
    tugs:"拖船情况",
    vhf:"高频通信",
    wasteDisposal:"废物污染",
    weatherTide:"天气/潮汐",
    webSite:"网址"
  },
  tonnage:{
      modifiedinfo:'船舶动态信息修改成功',
      releaseinfo:'船舶动态信息发布成功',
      deletedinfo:'船舶动态信息删除成功',
      built:'建造日期',
      name:'船舶动态',
      type:'类型',
      vesselName:'船名',
      dwt:'载重吨',
      openArea:'空船海域',
      openDate:'空船时间',
      endDate:'空船结束时间',
      openPort:'空船港口',
      come:'来源',
      sender_time:'发布时间',
      vs:'匹配对比',
      dataanalysis:'船舶动态数据分析',
    lalong:'经纬度',
    seaarea:'所属海域',
    createdTime:'识别时间',
    aishibie:'信息识别'
  },
  cargo:{
    modifiedinfo:'货盘信息修改成功',
    releaseinfo:'货盘信息发布成功',
    deletedinfo:'货盘信息删除成功',
    name:'货盘',
    type:'类型',
    vesselName:'货名',
    dataanalysis:'货盘数据分析',
    please_vesselName:'请输入货名',
    dwt:'载重吨',
    please_dwt:'请输入载重吨',
    openArea:'空船海域',
    emptytime:'空船时间',
    openDate:'空船时间',
    endDate:'空船结束时间',
    openPort:'空船港口',
    come:'来源',
    shorthandname:'货名简写',
    please_shorthandname:'请输入货名简写',
    volume:'货量',
    please_volume:'请输入货量',
    loading_port:'装货港',
    please_loading_port:'请输入装货港',
    dischargePort:'卸货港',
    please_dischargePort:'请输入卸货港',
    start_end_date:'受载期',
    start_date:'受载期始',
    please_start_date:'受载期始',
    end_date:'受载期止',
    please_end_date:'受载期止',
    load_unload_rate:'装卸率',
    loadrate:'装货率',
    dischargerate:'卸货率',
    please_load_rate:'请输入装率',
    please_unload_rate:'请输入卸率',
    commission:'佣金',
    please_commission:'请输入佣金',
    sender:'发布人',
    please_sender:'请输入发布人',
    sendTime:'发布时间',
    please_sendTime:'请输入发布时间',
    memo:'其他要求',
    please_memo:'请输入其他要求',
    modify:'修改信息',
    release:'发布消息',
    reset:'重置',
    issuetime:'发布时间',
    please_issuetime:'请输入天数',
    search:'搜索',
    card:'卡片',
    list:'列表',
    tonnage:'船舶动态',
    matching:'匹配',
    unit:'单位',
    sszz:'随时装载',
    shgz:'随后告知',
    tyzq:'同意租期',
    frt:'运费',
    cargotype:'货物类型',
    sf:'积载因子',
    crane:'装卸能力',
    package:'包装',
    maxHeight:'最大高度',
    maxWidth:'最大宽度',
    maxWeight:'最大重量',
    maxLength:'最大长度',
    maxsize:'最大尺寸',
    transportMode:'运输模式',
    currencyUnit:'币种',
    term:'条款',
    loadrequest:'装载请求',
    cargoName:'货名',
    cargoname:'货盘信息',
    quantity:'货量',
    laycan:'受载期',
    laycanstart:'受载期开始',
    laycanend:'受载期结束',
    loadingport:'装货港',
    dischargingport:'卸货港',
    loadingfirstport:'装货港',
    loadingsecondport:'装货港',
    dischargingfirstport:'卸货港',
    dischargingsecondport:'卸货港',
    sender_time:'发布时间',
    opration:'操作'
  },
  tct:{
    modifiedinfo:'租船信息修改成功',
    releaseinfo:'租船信息发布成功',
    deletedinfo:'租船信息删除成功',
    durationtype_please:'请选择租期单位',
    issue:'发布',
    tcmatching:'租船匹配',
    dataanalysis:'租船数据分析',
    name:'租船',
    tct:'租船信息',
    account:'租家',
    vessel_type:'船型',
    delivery_area:'交船地点',
    redelivery_area:'还船地点',
    delivery_port:'交船港口',
    redelivery_port:'还船港口',
    sea_area:'海域',
    port:'港口',
    laycan:'受载期',
    intent_cargo:'意向货物',
    duration:'租期',
    addcomm:'佣金',
    age:'船龄',
    max_age:'最大船龄',
    memo:'其他要求',
    vessel_name:'船名',
    dwt:'载重吨',
    open_area:'空船海域',
    open_date:'空船时间',
    sender:'发布人',
    send_time:'发布时间',
    please_issuetime:'请输入天数',
    release:'发布信息',
    search:'搜索',
    reset:'重置',
    matching:'匹配',
    please_select:'请选择',
    start_date:'开始日期',
    end_date:'结束日期',
    enter_accountname:'租家名称',
    decknum:'甲板数量',
    hatchnumber:'舱盖数量',
    holdnumber:'货舱数量',
    crane:'装卸能力',
    grain:'散装舱容',
    come:'来源',
    sender_time:'发布时间'
  },
  tctt:{
    name:'租船',
    vessel_name:'船名',
    dwt:'载重吨',
    deliveryarea:'交船海域',
    maxage:'最大船龄',
    open_area:'空船海域',
    open_date:'空船时间',
    come:'来源',
    sender_time:'发布时间'
  },
  shiptrade:{
    modifiedinfo:'船舶贸易信息修改成功',
    releaseinfo:'船舶贸易信息发布成功',
    deletedinfo:'船舶贸易信息删除成功',
    name:'船舶贸易',
    built:'建造日期',
    type:'船型',
    vessel_name:'船名',
    dwt:'载重吨',
    IMO:'IMO',
    releasing_area:'交付海域',
    releasing_time:'交付时间',
    come:'来源',
    sender_time:'发布时间',
    broker:'代理人',
    owner:'所有者',
    recorder:'发布人',
    record_time:'发布时间',
    memo:'其他要求',
    select:'请选择',
    dataanalysis:'贸易数据分析',
    rules:{
      rvessel_name:'请输入船名',
      rdwt:'请输入载重吨',
      rbuilt:'请输入建造日期',
      rtype:'请选择船型',
      rreleasing_time_start:'请输入开始日期',
      rreleasing_time_end:'请输入结束日期',
      rreleasing_area:'请输入交付海域',
      rreleasing_port:'请输入交付港口',
    }
  },
  estimate:{
    four:`\u3000\u3000\u3000货名\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000货量\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000装卸港\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000运费`,
    cargoname:'货名',
    quantity:'货量',
    ldport:'装卸港',
    feight:'运费',
    Handel1:'操作',
    collect:'收藏',
    vesselname:'船名',
    voyage_num:'航次',
    totalcost:'总成本',
    loss:'亏损',
    voyage:{
      successadd:'航次估算信息添加成功',
      goonfill:'请继续填写信息！',
      name:'航次信息',
      vesselname:'船名',
      voyage:'航次',
      dwt:'载重吨',
      cbm:'舱容',
      knot:'航速'
    },
    ship:{
      name:'船舶状态',
      shipstatus:'装载状态',
      port:'港口',
      cargoname:'货名',
      quantity:'货量',
      feight:'运费',
      feighttax:'运费税',
      portfees:'港口使费',
      discountcomm:'回扣佣金',
      brokercomm:'经纪人佣金',
      others:'其他费用',
      distance:'航程',
      seadays:'航行天数',
      idledays:'待港天数',
      workdays:'作业天数',
      accuratetominutes:'精确到分',
      addindown:'在下方添加'

    },
    cargo:{
      name:'货物信息',
      loadingport:'装货港',
      dischargeport:'卸货港',
      dataanalysis:'货盘数据分析',
    },

    person:{
      mytonnage:'我的船盘',
      collectedtonnage:'收藏的船盘',
      mycargo:'我的货盘',
      collectedcargo:'收藏的货盘',
      mytct:'我的租船',
      collectedtct:'收藏的租船',
    },
    fuel:{
      name:'燃油消耗',
      fueltype:'燃油种类',
      sailballast:'空载航行',
      heavysail:'重载航行',
      waitport:'待港',
      work:'工作',
      fuelprice:'燃油价格'
    },
    rent:{
      name:'船舶租金（$）',
      rentperday:'日租金',
      commission:'租金佣金',
      Equivalentrentlevel:'相当租金水平'
    },
    otherfee:{
      name:'其他费用（$）',
      Demurrage:'滞期费',
      dispatchfee:'速遣费',
      subsidy:'各类补贴',
      warehousesweepfee:'扫仓费'
    },
    result:{
      name:'航程计算结果',
      totaldistance:'总航程距离',
      totaldays:'总航行时间',
      totalseadays:'总在港时间',
      allvoyagetime:'航次总时间',
      firsteta:'预计离港时间',
      lastetd:'预计到港时间'
    },
    allcost:{
      name:'总成本（$）',
      totalfuel:'燃油费',
      totalportfee:'港口使费',
      addresscomm:'回扣佣金',
      brokercomm:'经纪人佣金',
      rent:'租金',
      rencomm:'租金佣金',
      tontax:'吨税',
      tontax_:'吨税=',
      othercost:'其他费用',
      totalcost:'总成本'
    },
    budget:{
      name:'收益（$）',
      freightrevenue:'运费收益',
      loss:'亏损',
      profit:'利益',
      profitper:'单位净收益',
      profitperday:'每日净收益',
    },
    danwei:{
      days:'天'
    }
  },

  personcenter:{
    pasword:'包括数字和字母，密码至少八位',
    personalcenter:'个人中心',
    username:'用户名',
    please_username:'请输入用户名',
    password:'密码',
    name:'姓名',
    mailbox:'邮箱',
    qq:'QQ',
    twitter:'Twitter',
    birth:'生日',
    accountduty:'用户类型',
    skype:'skype',
    facebook:'Facebook',
    whatsapp:'Whatsapp',
    department:'公司',
    phonenumber:'手机号码',
    please_phone:'请输入手机号',
    please_rphone:'请输入正确的手机号',
    modifyinfor:'修改信息',
    cancel:'取消',
    accountinfor:'账户信息',
    amountnumber:'金额/数量',
    recharge:'充值',
    top_upamount:'充值金额',
    walletamount:'钱包金额',
    pointsnumber:'积分数量',
    comfirmclose:'确认关闭？',
    customized:'自定义',
    paymentway:'充值方式',
    gotopay:'前去支付',
    wechat:'微信',
    alipay:'支付宝',
    wechatpay:'微信支付',
    alipaypay:'支付宝支付',
    yuepay:'余额支付成功',
    bankcard:'银行卡',
    purse:'钱包',
    please_account:'请输入金额',
    please_number:'请输入数量',
    retirevinghistory:'检索历史',
    inforcategory:'信息类别',
    all:'所有',
    tonnage:'船舶动态信息',
    cargo:'货盘信息',
    tct:'租船信息',
    keyword:'关键字',
    inquire:'查询',
    resultlist:'结果列表',
    retrievecondition:'检索条件',
    result:'结果',
    retrievetime:'检索时间',
    delete:'删除',
    viewperson:'查看个人信息',
    account:'账号',
    sex:'性别',
    changepassword:'修改密码',
    originalpwd:'原密码',
    newpwd:'新密码',
    please_oldpwd:'请输入原密码',
    please_newpwd:'请设置新密码',
    confirmnewpwd:'确认新密码',
    newpwdnoold:'新密码不能与原密码相同',
    twicepwd:'两次密码不一致',
    jifenyue:'积分余额',
    jifenmingxi:'积分明细',
    jifenjilu:'积分记录',
    time:'时间',
    type:'类型',
    jifenconsumer:'消费数量',
    jifenearn:'获取数量',
    consumerlist:'消费记录',
    receiverlist:'获取记录',
    consumeinteger:'消耗积分',
    getinteger:'获取积分',
    memo:'备注',
    qianbaoyue:'钱包余额',
    walletrecharge:'钱包充值',
    integralrecharge:'积分充值',
    yuan:'元',
    qianbaomingxi:'钱包明细',
    qianbaojilu:'钱包记录',
    jine:'金额',
    man:'男',
    woman:'女',
  },
  email:{
    emailconnect:'邮箱连接成功',
    accountadd:'账户邮箱添加成功',
    account:'账户名称',
    mailbox:'邮箱地址',
    maildownload:'邮件下载',
    mailsettings:'邮件设置',
    accountdelete:'删除账号',
    please_email:'请正确填写邮箱',
    please_input:'请输入邮件内容',
    emailoriginal:'查看原邮件',
    emailoriginalcontent:'原邮件内容',
    email_content:'邮件内容',
    currentaccount:'当前账号',
    seletemail:'从邮件中提取',
    extractemail:'AI识别',
    accountlist:'账号列表',
    emaildownload:'邮件下载',
    downloadmailsetting:'下载邮件设定',
    date:'日期',
    starttime:'开始时间',
    endtime:'结束时间',
    mailform:'邮件类型',
    reset:'重置',
    DownloadProgressMeter:'下载信息',
    stop:'停止',
    MailType:'邮件类型选择',
    mailtheme:'主题',
    sender:'发件人',
    recipient:'收件人',
    sendtime:'发布时间',
    ExtractProgressMeter:'抽取进度条',
    ExtractTips:'抽取提示',
    extract:'抽取',
    clear:'清空',
    timeframe:'时间范围',
    email:'默认抽取邮箱',
    emailaddress:'邮箱地址',
    retrieval:'检索',
    lastpage:'上一页',
    nextpage:'下一页',
    cancel:'取消',
    ok:'确定',
    modifyinformation:'修改信息',
    matchinformation:'匹配信息',
    deleteinformation:'删除信息',
    userinformation:'用户信息',
    accountname:"您的姓名(Y):",
    mailaddress:"电子邮件地址(E):",
    mailsetting:'邮箱设置',
    Internetmailsetting:'Internet电子邮件设置',
    addaccount:'添加账户',
    serverinformation:'服务器信息',
    mailservertype:'邮件服务器类型',
    accounttype:'账户类型',
    Password:'密码(P):',
    UserID:'用户名(U):',
    IncomingMailServer:'接收邮件服务器 POP/SMTP：',
    ServerPortinformation:'服务器端口号',
    IncomingServer:'传入服务器',
    Usedefault:'使用默认',
    ServerTimeoutSetting:'设置服务器连接超时时间',
    min:'分钟',
    AccountTesting:'账户测试',
    TestAccountSetting:'测试账户设置',
    finish:'完成',
    Logininformation:'登录信息',
    sentence1:'您想要保存您的修改吗？',
    sentence2:'您想要匹配该条信息吗？',
    sentence3:'您想要删除该条信息吗？',
    sentence4:'这些都是使电子邮件账户正常运行的必须设置',
    sentence5:'需要加密连接',
    sentence6:'在当前页面输入信息之后，你可以通过点击下面的按钮去测试你的账户。需要连接网络。',
    /*jx add*/ 
    extractmessage:'AI识别详情',
    pause:"停 止",
    continue:"继 续"
    /*********/
  },
  file:{
    selectfile:'选取文件上传',
    supportfile:'仅支持文档或图片，大小不超过 2M',
    filesucc:'文件提交成功',
    filefail:'文件提交失败',
    file_size:'上传文件大小不超过 2M',
    downsucc:'下载成功',
  },
  dataanalysis:{
    EmptyShipDate:'空船日期',
    EmptySeaare:'空船海域',
    shiptype:'船舶类型',
    DWT:'载重吨',
    to:'至',
    startmonth:'开始月份',
    endmonth:'结束月份',
    auto:'自动',
    Annually:"按年",
    monthly:'按月',
    Byday:'按日',
    thismonth:'本月',
    thisyear:'本年',
    NearlySixMonths:'近六个月',
    date:'日期',
    number:'个数',
  }

}


  export default zn;
