import { createI18n } from 'vue-i18n'
import enLocale from 'element-plus/lib/locale/lang/en'
import zhLocale from 'element-plus/lib/locale/lang/zh-cn'
import cn from './lang/zn_CN'
import en from './lang/en'
const messages =  {
      [enLocale.name]: {
            // el 这个属性很关键，一定要保证有这个属性，
            el: enLocale.el,
            // 定义您自己的字典，但是请不要和 `el` 重复，这样会导致 ElementPlus 内部组件的翻译失效.
            message: en
      },
      [zhLocale.name]: {

            el: zhLocale.el,
            // 定义您自己的字典，但是请不要和 `el` 重复，这样会导致 ElementPlus 内部组件的翻译失效.
            message:cn

      },
      testLocale: {
            el: {},
            // 没有定义 message 字段，会 fallback 回到 en 去, fallbackLocale 的定义在下方 👇
      },
}
const i18n = createI18n({
      //locale:localStorage.getItem('lang') || 'cn',
      //默认是localStorage里面设置的语言或者是中文
      locale:localStorage.getItem('lang') || zhLocale.name,
      //没有中文的时候默认使用英文
      fallbackLocale: enLocale.name,
      messages
  })
export default i18n
