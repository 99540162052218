import request from '/src/utils/request'

export function createWithArray(param) {
  return request({
    url: `/metadata/metadata/users/createWithArray`,
    method: 'post',
    data: param
  })
}

export function createWithList(param) {
  return request({
    url: `/metadata/metadata/users/createWithList`,
    method: 'post',
    data: param
  })
}
//登录
export function login(account,pwd,pkversion) {
  const params = {
    'account': account,
    'passwd': pwd,
    'pkVersion':pkversion,
    'systemCode':1
  }
  return request({
    url: `/api/auth/loginByAccount`,
    method: 'post',
    data:params
  })
}
//发送手机验证码
export function getphonecode(param) {
//  console.log(param)
  return request({
    url: `/api/auth/SendCode?phoneNumber=`+param,
    method: 'get',
  })
}
//发送手机验证码
export function getyamphonecode(param) {
  //console.log(param)
  return request({
    url: `/api/sendMs?phoneNumber=`+param,
    method: 'get',
  })
}
//手机验证码登录
export function loginbymobile(account,pwd) {
  const params = {
    'mobile': account,
    'checkCode': pwd
  }
  return request({
    url: `/api/auth/loginByMobile`,
    method: 'post',
    data:params
  })
}

//获取用户详细信息
export function getAccountInfo() {
  return request({
    url: `/api/account/getDetil`,
    method: 'post'
  })
}

export function getAccountDetailInfo(param,pkversion) {
  const query = {
    message:param,
    pkVersion:pkversion,
  }
  return request({
    url: `/api/accountinfo/getDetil-secret`,
    method: 'post',
    data:query
  })
}
export function saveAccountDetail(param,pkversion){
  const query = {
    message:param,
    pkVersion:pkversion,
  }
  return request({
    url:`/api/accountinfo/save-secret`,
    // url:`/api/accountinfo/save`,
    method:'post',
    data:query
  })
}
export function saveAccount(param){
  return request({
    url:`/api/account/usersave`,
    method:'post',
    data:param
  })
}
export function updatepass(param){
  return request({
    url:`/api/auth/updatePwd`,
    method:'post',
    data:param
  })
}
export function forgetPassword(param){
  return request({
    url:`/api/account/forgetPass`,
    method:'post',
    data:param
  })
}
export function validPassword(param){
  return request({
    url:`/api/account/validOnly`,
    method:'post',
    data:param
  })
}export function rePassword(param){
  return request({
    url:`/api/account/rePass`,
    method:'get',
    data:param
  })
}

export function logout() {
  return request({
    url: `/api/auth/logout`,
    method: 'post'
  })
}
export function getshipinfo(param) {
  return request({
    url: `/api/measserver/ship/getinfo/${param}`,
    method: 'post',
  })
}
//增加船舶规范
export function addship(param) {
  return request({
    url: `/api/measserver/ship/save`,
    method: 'post',
    data: param
  })
}


export function getUserbyname(username) {
  return request({
    url: `/metadata/metadata/users/${username}`,
    method: 'get'
  })
}

export function updateduser(username) {
  return request({
    url: `/metadata/metadata/users/${username}`,
    method: 'put'
  })
}

export function deleteuser(username) {
  return request({
    url: `/metadata/metadata/users/${username}`,
    method: 'post'
  })
}
export function findcargoByMultiFilter(param) {
  return request({
    url: `/metadata/metadata/cargoNames/findByMultiFilter`,
    method: 'get',
    data: param
  })
}

export function findcargoname(cargoNameId) {
  return request({
    url: `/metadata/metadata/cargoNames/${cargoNameId}`,
    method: 'get'
  })
}

export function updatecargoname(cargoNameId) {
  return request({
    url: `/metadata/metadata/cargoNames/${cargoNameId}`,
    method: 'post'
  })
}

export function deletecargoname(cargoNameId) {
  return request({
    url: `/metadata/metadata/cargoNames/${cargoNameId}`,
    method: 'delete'
  })
}
//邮箱注册
export function createuser(param) {
 // console.log(param)
  return request({
    url: `/api/register`,
    method: 'post',
    data: param
  })
}
//电话号注册
export function createphone(param) {
  return request({
    url: `/api/registerByPhone`,
    method: 'post',
    data: param
  })
}
//发送邮件激活
export function send_email(param) {
  return request({
    url:`/metadata/metadata/sendemail`,
    method:'post',
    data:param
  })
}

//搜索船名
export  function shipnameslist() {
  return request({
    url:`/metadata/metadata/ship/shipnamelist`,
    method:'post'
  })
}

export function findabbByMultiFilter(param) {
  return request({
    url: `/metadata/metadata/abbreviations/findByMultiFilter`,
    method: 'get',
    data: param
  })
}
export function findabbreviation(abbreviationId) {
  return request({
    url: `/metadata/metadata/abbreviations/${abbreviationId}`,
    method: 'get'
  })
}
export function updateabbreviation(abbreviationId) {
  return request({
    url: `/metadata/metadata/abbreviations/${abbreviationId}`,
    method: 'post'
  })
}
export function deleteabbreviation(abbreviationId) {
  return request({
    url: `/metadata/metadata/abbreviations/${abbreviationId}`,
    method: 'delete'
  })
}
export function addcargoNames(param) {
  return request({
    url: `	/metadata/metadata/cargoNames`,
    method: 'post',
    data: param
  })
}
export function updatecargoNames(param) {
  return request({
    url: `/metadata/metadata/cargoNames`,
    method: 'put',
    data: param
  })
}
export function updatepackageTypes(param) {
  return request({
    url: `/metadata/metadata/packageTypes`,
    method: 'post',
    data: param
  })
}
export function findpackageTypesByMultiFilter(param) {
  return request({
    url: `/metadata/metadata/packageTypes/findByMultiFilter`,
    method: 'post',
    data: param
  })
}
export function findpackageType(packageTypeId) {
  return request({
    url: `/metadata/metadata/packageTypes/${packageTypeId}`,
    method: 'get'
  })
}
export function updatepackageType(packageTypeId) {
  return request({
    url: `/metadata/metadata/packageTypes/${packageTypeId}`,
    method: 'post'
  })
}
export function deletepackageType(packageTypeId) {
  return request({
    url: `/metadata/metadata/packageTypes/${packageTypeId}`,
    method: 'delete'
  })
}
export function addabb(param) {
  return request({
    url: `/metadata/metadata/abbreviations`,
    method: 'post'
  })
}
export function updateabb(param) {
  return request({
    url: `/metadata/metadata/abbreviations`,
    method: 'post'
  })
}
export function updatecargoTypes(param) {
  return request({
    url: `/metadata/metadata/cargoTypes`,
    method: 'put'
  })
}
export function findcargoTypeByMultiFilter(param) {
  return request({
    url: `/metadata/metadata/cargoTypes/findByMultiFilter`,
    method: 'get'
  })
}
export function findcargoTypes(cargoTypeId) {
  return request({
    url: `/metadata/metadata/cargoTypes/${cargoTypeId}`,
    method: 'get'
  })
}
export function updatecargoTypesbyid(cargoTypeId) {
  return request({
    url: `/metadata/metadata/cargoTypes/${cargoTypeId}`,
    method: 'post'
  })
}
export function deletecargoTypes(cargoTypeId) {
  return request({
    url: `/metadata/metadata/cargoTypes/${cargoTypeId}`,
    method: 'delete'
  })
}
export function addpackageTypes(param) {
  return request({
    url: `/metadata/metadata/packageTypes`,
    method: 'post',
    data: param
  })
}
export function updateshipTypes(param) {
  return request({
    url: `/metadata/metadata/shipTypes`,
    method: 'put',
    data: param
  })
}
export function findshipTypesByMultiFilter(param) {
  return request({
    url: `/metadata/metadata/shipTypes/findByMultiFilter`,
    method: 'get',
    data: param
  })
}
export function findshipTypes(shipTypeId) {
  return request({
    url: `/metadata/metadata/shipTypes/${shipTypeId}`,
    method: 'get'
  })
}
export function updateshipTypesbyid(shipTypeId) {
  return request({
    url: `/metadata/metadata/shipTypes/${shipTypeId}`,
    method: 'post'
  })
}
export function deleteshipTypes(shipTypeId) {
  return request({
    url: `/metadata/metadata/shipTypes/${shipTypeId}`,
    method: 'delete'
  })
}
export function addcargoTypes(param) {
  return request({
    url: `/metadata/metadata/cargoTypes`,
    method: 'post'
  })
}
export function addseaareaCountrys(param) {
  return request({
    url: `/metadata/metadata/seaareaCountrys`,
    method: 'post'
  })
}
export function updateseaareaCountrys(param) {
  return request({
    url: `/metadata/metadata/seaareaCountrys`,
    method: 'put'
  })
}
export function findseaareaCountrys(seaareaCountryId) {
  return request({
    url: `/metadata/metadata/seaareaCountrys/${seaareaCountryId}`,
    method: 'get'
  })
}
export function updateseaareaCountrysbyid(seaareaCountryId) {
  return request({
    url: `/metadata/metadata/seaareaCountrys/${seaareaCountryId}`,
    method: 'post'
  })
}
export function deleteseaareaCountrys(seaareaCountryId) {
  return request({
    url: `/metadata/metadata/seaareaCountrys/${seaareaCountryId}`,
    method: 'delete'
  })
}
export function addshipTypes(param) {
  return request({
    url: `/metadata/metadata/shipTypes`,
    method: 'post',
    data: param
  })
}
export function addcountrys(param) {
  return request({
    url: `/metadata/metadata/countrys`,
    method: 'post',
    data: param
  })
}
export function updatecountrys(param) {
  return request({
    url: `/metadata/metadata/countrys`,
    method: 'put',
    data: param
  })
}
export function findcountrysByMultiFilter(param) {
  return request({
    url: `/metadata/metadata/countrys/findByMultiFilter`,
    method: 'get',
    data: param
  })
}
export function findcountrys(countryId) {
  return request({
    url: `/metadata/metadata/countrys/${countryId}`,
    method: 'get'
  })
}
export function updatecountrysbyid(countryId) {
  return request({
    url: `/metadata/metadata/countrys/${countryId}`,
    method: 'post'
  })
}
export function deletecountrys(countryId) {
  return request({
    url: `/metadata/metadata/countrys/${countryId}`,
    method: 'delete'
  })
}
export function addseaareas(param) {
  return request({
    url: `/metadata/metadata/seaareas`,
    method: 'post',
    data: param
  })
}
export function updateseaareas(param) {
  return request({
    url: `/metadata/metadata/seaareas`,
    method: 'put',
    data: param
  })
}
export function findseaareasByMultiFilter(param) {
  return request({
    url: `/metadata/metadata/seaareas/findByMultiFilter`,
    method: 'get',
    data: param
  })
}
export function findseaareas(seaareaId) {
  return request({
    url: `/metadata/metadata/seaareas/${seaareaId}`,
    method: 'get'
  })
}
export function updateseaareasbyid(seaareaId) {
  return request({
    url: `/metadata/metadata/seaareas/${seaareaId}`,
    method: 'post'
  })
}
export function deleteseaareas(seaareaId) {
  return request({
    url: `/metadata/metadata/seaareas/${seaareaId}`,
    method: 'delete'
  })
}
export function updatecompanys(param) {
  return request({
    url: `/metadata/metadata/companys`,
    method: 'put',
    data: param
  })
}
export function findByCompanyEname(param) {
  return request({
    url: `/metadata/metadata/companys/findByCompanyEname`,
    method: 'get',
    data: param
  })
}
export function findcompanysByMultiFilter(param) {
  return request({
    url: `/metadata/metadata/companys/findByMultiFilter`,
    method: 'get',
    data: param
  })
}
export function findcompanys(companyId) {
  return request({
    url: `/metadata/metadata/companys/${companyId}`,
    method: 'get'
  })
}
export function updatecompanysbyid(companyId) {
  return request({
    url: `/metadata/metadata/companys/${companyId}`,
    method: 'post'
  })
}
export function deletecompanys(companyId) {
  return request({
    url: `/metadata/metadata/companys/${companyId}`,
    method: 'delete'
  })
}
export function findportsByMultiFilter(param) {
  return request({
    url: `/metadata/metadata/ports/findByMultiFilter`,
    method: 'get',
    data: param
  })
}
export function findports(portId) {
  return request({
    url: `/api/measserver/port/getinfo/${portId}`,
    method: 'post'
  })
}
export function updateportsbyid(portId) {
  return request({
    url: `/metadata/metadata/ports/${portId}`,
    method: 'post'
  })
}
export function deleteports(portId) {
  return request({
    url: `/metadata/metadata/ports/${portId}`,
    method: 'delete'
  })
}
export function addcompanys(param) {
  return request({
    url: `/metadata/metadata/companys`,
    method: 'get',
    data: param
  })
}
export function findships(shipId) {
  return request({
    url: `/metadata/metadata/ships/${shipId}`,
    method: 'get'
  })
}
export function updateships(shipId) {
  return request({
    url: `/metadata/metadata/ships/${shipId}`,
    method: 'post'
  })
}
export function deleteships(shipId) {
  return request({
    url: `/metadata/metadata/ships/${shipId}`,
    method: 'delete'
  })
}
export function addports(param) {
  return request({
    url: `/metadata/metadata/ports`,
    method: 'post',
    data: param
  })
}
export function updateports(param) {
  return request({
    url: `/metadata/metadata/ports`,
    method: 'put',
    data: param
  })
}
export function findByPortEname(param) {
  return request({
    url: `/metadata/metadata/ports/findByPortEname`,
    method: 'get',
    data: param
  })
}
export function findByPortCode(param) {
  return request({
    url: `/metadata/metadata/ports/findByPortCode`,
    method: 'get',
    data: param
  })
}
export function updateship(param) {
  return request({
    url: `/metadata/metadata/ships`,
    method: 'put',
    data: param
  })
}
export function findshipsByVesselName(param) {
  return request({
    url: `/metadata/metadata/ships/findByVesselName`,
    method: 'get',
    data: param
  })
}
//高级检索
export function findshipsByMultiFilter(param) {
  return request({
    url: `/metadata/metadata/ships/findByMultiFilter`,
    method: 'get',
    data: param
  })
}

