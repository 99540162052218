<template>
  <div id="app" :class="{isPC: isMobile === false}" :style="{ height: bodyHeight }">
          <router-view v-if="isRouterAlive" class="router-view">
          </router-view>
  </div>
</template>

<script>
import _isMobile from '../src/utils/isMobile'
import { mapGetters,mapMutations } from 'vuex'
// import base_ip_config from '@/config/base_ip.js'


export default {
  provide(){
    return {
      reload:this.reload
    }
  },
   data() {
     return {
       isRouterAlive:true,
       bodyHeight:''
     }
   },
   computed:{
     ...mapGetters(['isMobile','loginStatus'])
   },
   methods:{
     ...mapMutations(['changeIsMobile']),
     reload(){
       this.isRouterAlive = false;
       this.$nextTick(function() {
         this.isRouterAlive = true;
       })
     }
   },
   created() {
    if(this.loginStatus == true){
      this.$store.dispatch('global/getMenu')
    }
     if(_isMobile()) {
       this.changeIsMobile(true)
     }
   },
   mounted(){
     // console.log(base_ip_config)
     if(this.isMobile){
        //解决安卓移动端输入框弹出挤压页面高度
        //在网站加载时获取屏幕高度，将100%height转化为固定的px高度保证不被挤压
        this.bodyHeight = document.documentElement.clientHeight +'px'
     }
   }
}
</script>

<style lang="scss">
#app {
  width:100%;
  height:100%;
  //background: pink;
}
.isPC{
  min-width: 1280px;
  //min-height: 600px;
  ::-webkit-scrollbar{
    width:10px;
    height:1px;
  }
  ::-webkit-scrollbar-thumb{
    border-radius:3px;
    //background-color:#55c0ea;
    background: #409eff;
  }
  ::-webkit-scrollbar-track{
    //box-shadow: inset 0 0 5px rgba(0,0,0,0.1);
    background: #ededed;
    border-radius:3px;
  }
}
a{
  text-decoration:none;
}
.el-message{
  min-width: 300px;
  border-radius: 10px;
}
.el-table{
  .el-table__expanded-cell{
    padding: 10px;
  }
}


</style>
