
import { createApp } from "vue";
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import { Drawer } from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css'
import i18n from '../src/lanauage'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './assets/img/iconfont.css'
import echarts from "echarts";
import './permission'
import Print from "../src/utils/first-demo-pdf"
import './utils/validate'
import $ from "jquery"
import mitt from 'mitt';
import _ from "lodash"






//import preventReClick from './utils/preventReClick'
// import mapBoxGl from 'mapbox-gl'
// Vue.prototype.$mapboxgl = mapBoxGl

// const app = createApp({})
// app.directive('preventReClick', {
//   // 当被绑定的元素挂载到 DOM 中时……
//     // 聚焦元素
//     mounted(el, binding) {
//       // el.focus()
//       console.log(el);
//       console.log(binding);
//       // console.log('自定义局部指令注册成功！');
//       el.addEventListener('click', () => {
//         if (!el.disabled) {
//           console.log(123);
//           el.disabled = true;
//           setTimeout(() => {
//             el.disabled = false;
//           }, binding.value || 300);
//         }
//       });
//     }
// })
// main.js







window.vm=createApp(App)
.use(store)
.use(Print)
.use(router)
.use(Drawer)
.use(echarts)
  //.use(preventReClick)
.use(
    ElementPlus,
    { i18n: i18n.global.t }
)
.use(i18n)
.use($)
.use(mitt)
.mount("#app");
