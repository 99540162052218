
const fold = {
    state: {
          isSideBarMobileFold: false, //控制移动端SideBar的折叠
          isHaiTuDrawer:false,//控制海图drawer
    },
    mutations: {
       changeMobileSideBarFold(state, payload) {
         state.isSideBarMobileFold = payload
       },
       changeHaiTuDrawer(state,payload) {
        // console.log(payload)
         state.isHaiTuDrawer = payload
       }
    }
  }
  
  export default fold