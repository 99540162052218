import request from "/src/utils/request";
//连接邮箱测试
export function connectaccount(param) {
  return request({
    url: `api/email_account/connect`,
    method: "post",
    data: param,
  });
}
//前台获取EmailAccount列表
export function getemailaccount() {
  return request({
    url: `api/email_account/getListFront`,
    method: "get",
  });
}
//添加账户邮箱
export function adduseraccount(param) {
  return request({
    url: `api/email_account/save`,
    method: "post",
    data: param,
  });
}
//修改账户邮箱
export function updateuseraccount(param) {
  return request({
    url: `api/email_account/update/${param.id}`,
    method: "post",
    data: param,
  });
}
//删除账户邮箱
export function deleteuseraccount(param) {
  return request({
    url: `api/email_account/delete/${param}`,
    method: "post",
  });
}
//通过id查询账户邮箱
export function getinfouseraccount(param) {
  return request({
    url: `api/email_account/getInfo/${param}`,
    method: "get",
  });
}
//邮件下载
export function emaildownload(param) {
  return request({
    url: `api/email_account/download`,
    method: "post",
    data: param,
  });
}
/*jx add 2023/12/09:新增获取下载进度接口*/
export function emaildownloadrate(param) {
  return request({
    url: `api/email_account/download/${param}`,
    method: "get",
    data: param,
  });
}
/*jx add 2023.12.25 用户点击“确定覆盖”后后端将数据库中重复邮件删除*/
export function DeletRepeatEmail(message)
{
  return request({
    url: `api/email_account/deletrepeatemail`,
    method: "post",
    data: message,
  });
}
//邮件下载停止
export function emailstopdownload(message) {
  return request({
    url: `api/email_account/stopdownload`,
    //`api/email_account/stopdownload`
    method: "post",
    data: message, // 将请求主体添加到请求中
  });
}

//邮件抽取
export function emailextract(param) {
  return request({
    url: `api/email_account/extract`,
    method: "post",
    data: param,
  });
}
/*jx add 24.1.10 新增功能：获取邮件抽取状态*/
export function emailextractstatus(param) {
  return request({
    url: `api/email_account/extract/${param}`,
    method: "get",
    data: param,
  });
}

//邮件检索
export function getemaillist() {
  return request({
    url: `api/email_detail/getListFront`,
    method: "get",
  });
}
//通过id更新邮件
export function updateemailbyid(param) {
  return request({
    url: `api/email_detail/update/${param.id}`,
    method: "post",
    data: param,
  });
}
//通过id查询邮件
export function searchemailbyid(param) {
  return request({
    url: `api/email_detail/getInfo/${param}`,
    method: "get",
  });
}
//获取特定类型的邮件列表
export function searchemailbytype(param) {
  return request({
    url: `api/email_detail/getList/${param.type}`,
    method: "get",
    data: param,
  });
}
//获取tonnage类型的邮件列表
export function tonnageemailcard(param) {
  return request({
    url: `api/email_parse_tonnage/getCard`,
    method: "post",
    data: param,
  });
}
export function tonnageemailpage(param) {
  return request({
    url: `/api/email_parse_tonnage/backcard/?page=${param}`,
    method: "get",
  });
}
export function cargoemailpage(param) {
  return request({
    url: `/api/email_parse_cargo/backcard/?page=${param}`,
    method: "get",
  });
}
export function tctemailpage(param) {
  return request({
    url: `/api/email_parse_tct/backcard/?page=${param}`,
    method: "get",
  });
}

//获取cargo类型的邮件列表
export function cargoemailcard(param) {
  return request({
    url: `api/email_parse_cargo/getCard`,
    method: "post",
    data: param,
  });
}
//获取tct类型的邮件列表
export function tctemailcard(param) {
  return request({
    url: `api/email_parse_tct/getCard`,
    method: "post",
    data: param,
  });
} //获取tct类型的邮件全部信息
export function tctemailList(param) {
  return request({
    url: `api/email_parse_tct/getList`,
    method: "get",
    data: param,
  });
}
//根据id查看cargo详情
export function detailcargo(param) {
  return request({
    url: `api/email_parse_cargo/getInfo/${param}`,
    method: "get",
    data: param,
  });
}
//根据id查看tonnage详情
export function detailtonnage(param) {
  return request({
    url: `api/email_parse_tonnage/getInfo/${param}`,
    method: "get",
    data: param,
  });
}
//根据id查看tct详情
export function detailtct(param) {
  return request({
    url: `api/email_parse_tct/getInfo/${param}`,
    method: "get",
    data: param,
  });
}
//更新tonnage邮件
export function updateextracttct(id, param) {
  return request({
    url: `api/email_parse_tct/update/${id}`,
    method: "post",
    data: param,
  });
}
//更新cargo邮件
export function updateextractcargo(id, param) {
  return request({
    url: `api/email_parse_cargo/update/${id}`,
    method: "post",
    data: param,
  });
}
export function updateextracttonnage(id, param) {
  return request({
    url: `api/email_parse_tonnage/update/${id}`,
    method: "post",
    data: param,
  });
}

//删除邮件(根据extract_id删除)
export function deleteemail(param) {
  return request({
    url: `api/email_detail/delete/${param}`,
    method: "post",
  });
}
//删除邮件（根据getcard的id删除）
export function deletetonnageemail(param) {
  return request({
    url: `api/email_parse_tonnage/delete/${param}`,
    method: "post",
  });
}
//删除邮件（根据getcard的id删除）
export function deletetctemail(param) {
  return request({
    url: `api/email_parse_tct/delete/${param}`,
    method: "post",
  });
}
//删除邮件（根据getcard的id删除）
export function deletecargoemail(param) {
  return request({
    url: `api/email_parse_cargo/delete/${param}`,
    method: "post",
  });
}
//获取邮件任务结果列表
export function getemailtask() {
  return request({
    url: `/api/email_account/email_task_result/getList`,
    method: "get",
  });
}

//获取某个邮件任务详情
export function getemailtaskbyId(param) {
  return request({
    url: `/api/email_account/email_task_result/getInfo/${param}`,
    method: "get",
  });
}

//抽取船舶动态信息邮件
export function extractTonnage(param) {
  return request({
    url: `/api/email_detail/extract_tonnage`,
    method: "post",
    data: param,
  });
}
//抽取租船信息邮件
export function extractTct(param) {
  return request({
    url: `/api/email_detail/extract_tct`,
    method: "post",
    data: param,
  });
}
//抽取货盘信息邮件
export function extractCargo(param) {
  return request({
    url: `/api/email_detail/extract_cargo`,
    method: "post",
    data: param,
  });
}
