// 注意事项 需使用ref获取dom节点，若直接通过id或class获取则webpack打包部署后打印内容为空 指定不打印区域 方法一. 添加no-print样式类
//
// <div class="no-print">不要打印我</div>
// 方法二. 自定义类名
//
// <div class="do-not-print-me-xxx">不要打印我</div>
// this.$print(this.$refs.print,{'no-print':'.do-not-print-me-xxx'}) // 使用
const Print = function (dom, options) {
  if (!(this instanceof Print)) return new Print(dom, options);
  this.options = this.extend({
    'noPrint': '.no-print'
  }, options);
    this.isDOM(dom)
    this.dom = this.isDOM(dom) ? dom : dom.$el;
    this.init();
};
Print.prototype = {
  init: function () {
    var content = this.getStyle() + this.getHtml();
    this.writeIframe(content);
  },
  extend: function (obj, obj2) {
    for (var k in obj2) {
      obj[k] = obj2[k];
    }
    return obj;
  },

  getStyle: function () {
    var str = "",
      styles = document.querySelectorAll('style,link');
    for (var i = 0; i < styles.length; i++) {
      str += styles[i].outerHTML;
    }
    str += "<style>" + (this.options.noPrint ? this.options.noPrint : '.no-print') + "{display:none;}</style>";
    return str;
  },

  getHtml: function () {
    return this.dom.outerHTML;
  },
  writeIframe: function (content) {
    var w, doc, iframe = document.createElement('iframe'),
    f = document.body.appendChild(iframe);
    iframe.id = "myIframe";
    //iframe.style = "position:absolute;width:0;height:0;top:-10px;left:-10px;";
    iframe.setAttribute('style', 'position:absolute;width:0;height:0;top:-10px;left:-10px;');
    w = f.contentWindow || f.contentDocument;
    doc = f.contentDocument || f.contentWindow.document; //文件
    doc.open();
    doc.write(content);
    doc.close();
    var _this = this
    iframe.onload = function(){
      _this.toPrint(w);
      setTimeout(function () {
        document.body.removeChild(iframe)
      }, 100)
    }
  },

  toPrint: function (frameWindow) {
    try {
      setTimeout(function () {
        frameWindow.focus();
        try {
          if (!frameWindow.document.execCommand('print', false, null)) {
            frameWindow.print();
          }
        } catch (e) {
          frameWindow.print();
        }
        frameWindow.close();
      }, 10);
    } catch (err) {
      //console.log('err', err);
    }
  },
  isDOM: (typeof HTMLElement === 'object') ?
    function (obj) {
      return obj instanceof HTMLElement;
    } :
    function (obj) {
      return obj &&
          typeof obj === 'object' &&
          obj.nodeType === 1 &&
          typeof obj.nodeName === 'string';
    }
};
const MyPlugin = {}
MyPlugin.install = function (Vue, options) {
  // 4. 添加实例方法
  Vue.config.globalProperties.$print = Print
}
export default MyPlugin
