import request from '/src/utils/request'

export function getportname(param) {
  return request({
    url: `/api/measserver/port/getname`,
    method: 'post',
    data: param
  })
}

export function getshiptypetree() {
  return request({
    url: `/api/measserver/shipType/getnameTree`,
    method: 'post'
  })
}
export function getshipname(param) {
  return request({
    url: `/api/measserver/ship/getname`,
    method: 'post',
    data:param
  })
}

export function getshipDetail(param) {
  return request({
    url: `/api/measserver/ship/getlist`,
    method: 'post',
    data:param
  })
}
export function getseaname(param) {
  return request({
    url: `/api/measserver/seaarea/getname`,
    method: 'post',
    data: param
  })
}

export function getcargoname(param) {
  return request({
    url: `/api/measserver/cargoName/getname`,
    method: 'post',
    data: param
  })
}
//货物类型
export function getcargotype(param) {
  return request({
    url: `/api/measserver/cargoType/getname`,
    method: 'post',
    data: param
  })
}
export function getcountryname(param) {
  return request({
    url: `/api/measserver/country/getname`,
    method: 'post',
    data: param
  })
}
export function getcompanyname(param) {
  return request({
    url: `/api/measserver/company/getname`,
    method: 'post',
    data: param
  })
}

export function getshiptype(param) {
  return request({
    url: `/api/measserver/shipType/getname`,
    method: 'post',
    data: param
  })
}

export function getlinername(param) {
  return request({
    url: `/api/voyagePlan/liner/getname`,
    method: 'post',
    data: param
  })
}
export function getvoyagename(param) {
  return request({
    url: `/api/vovageCalculator/voyage/getname`,
    method: 'post',
    data: param
  })
}
export function getsender(param) {
  return request({
    url: `/api/measserver/sender/getname`,
    method: 'post',
    data: param
  })
}
export function getseaarea(param) {
  return request({
    url: `/api/measserver/seaarea/getinfo/${param}`,
    method: 'post'
  })
}