import { getportname,getshipname,getcargoname,getcompanyname,getcountryname,getseaname,getlinername ,getvoyagename,getshiptype,getsender,getcargotype} from '../../api/associativeinput'
import zhLocale from "element-plus/lib/locale/lang/zh-cn";
const state={
  lang: localStorage.getItem('lang') ? localStorage.getItem('lang') : zhLocale.name ,
  //船舶联想输入信息
  vessel_name:[],
  tonnageList:[],
  cargoList:[],
  voyageplanList:[],
  matchobj:{},
  selectId:'',
  tctList:[],
  //联想输入

  linernames:[],  //船名
  linernamegroup:[],
  linerquery:{
    linerName:''
  },
  vesselnames:[],  //船名
  vesselnamegroup:[],
  vesselquery:{
    vesselName:''
  },
  cargoname:[],    //货名
  cargonamegroup:[],
  cargoquery:{
    cargoName:''
  },
  seaname:[],      //海域名
  seanamegroup:[],
  seaquery:{
    name:''
  },
  portnames:[],    //港口名
  portnamegroup:[],
  portquery:{
    seaAreaId:'',
    portName:''
  },
  countryname:[],  //国家名
  countrynamegroup:[],
  countryquery:{
    name:''
  },
  IMOnames:[],  //船舶编号名
  IMOnamegroup:[],
  IMOquery:{
    IMOName:''
  },
  companynames:[], //公司名
  companynamegroup:[],
  companyquery:{
    companyName:''
  },
  voyagenames:[],
  voyagenamegroup:[],
  voyagequery:{
    voyageName:''
  },
  typenames:[],
  shiptypegroup:[],
  typequery:{
    name:''
  },
  // 发布人邮箱联想输入
  sendernames:[],
  sendergroup:[],
  senderquery:{
    email:''
  }
}

const mutations={
  SET_VesselName(state, vesselname){
    state.vessel_name=vesselname
  },
  SET_Tonnageinfo(state, tonnageList){
    state.tonnageList=tonnageList
  },
  SET_SelectId(state,selectId){
    state.selectId=selectId
  },
  SET_Cargoinfo(state,cargoList){
    state.cargoList = cargoList
  },
  SET_Tctinfo(state,tctList){
    state.tctList = tctList
  },
  SET_Voyageplaninfo(state,voyageplanList){
    state.voyageplanList = voyageplanList
  },
  SET_MATCHOBJ(state,matchobj){
    state.matchobj = matchobj
  },
  SET_lang(state,lang){
    state.lang = lang
   // console.log("lang"+state.lang)
    localStorage.setItem('lang',state.lang)
  },
  //船名联想输入
  async querySearchVessel(state,queryString){
    state.vesselnames = []
    state.vesselquery.vesselName = queryString
    await getshipname(state.vesselquery).then(response => {
      if (response.error_code == 200)
        if(state.lang == 'en'){
         // console.log(state.lang)
          for (let item of response.datas) {
            state.vesselnames.push(
              {
                value: item.vesselEname,
                cname:item.vesselCname,
                id: item.id,
                dwt:item.dwt,
                built:item.built,
                handlingCapacity:item.handlingCapacity
              })
          }
        }
        else{
         // console.log(state.lang)
          for (let item of response.datas) {
            state.vesselnames.push(
              {
                value: (item.vesselCname == "") ? item.vesselEname:item.vesselCname,
                ename:(item.vesselEname == "")? item.vesselCname :item.vesselEname,
                id: item.id,
                dwt:item.dwt,
                built:item.built,
                handlingCapacity:item.handlingCapacity
              })
          }
        }
        if(state.vesselnames.length == 0){
          state.vesselnames.push({
            id:'-1',
            value:'无匹配结果',
            disabled: true,
          })
        }
    })
    state.vesselnamegroup = state.vesselnames;
  },

  //航线名联想输入
  querySearchLiner(state,queryString){
    state.linernames = []
    state.linerquery.linerName = queryString
    getlinername(state.linerquery).then(response => {
      if (response.error_code == 200)
        if(state.lang == 'en'){
        //  console.log(state.lang)
          for (let item of response.datas) {
            state.linernames.push(
              {
                value: item.linerEname,
                cname:item.linerCname,
                id: item.id
              })
          }
        }
        else{
         // console.log(state.lang)
          for (let item of response.datas) {
            state.linernames.push(
              {
                value: item.linerCname,
                ename:item.linerEname,
                id: item.id
              })
          }
        }
      if(state.linernames.length == 0){
        state.linernames.push({
          id:'-1',
          value:'无匹配结果',
          disabled: true,
        })
      }
    })
    state.linernamegroup = state.linernames;
  },
  //货名联想输入
  async querySearchCargo(state,queryString){
    state.cargonames = []
    state.cargoquery.cargoName = queryString
    await getcargoname(state.cargoquery).then(response => {
      if (response.error_code == 200)
        if(state.lang == 'en'){
          for (let item of response.datas) {
            state.cargonames.push(
              {
                value: item.cargoEname,
                cname:item.cargoCname =="" ? item.cargoEname : item.cargoCname,
                id: item.id,
                abbr:item.cargoNameAbbr
              })
          }
        }
        else{
          for (let item of response.datas) {
            state.cargonames.push(
              {
                value: item.cargoCname =="" ? item.cargoEname : item.cargoCname,
                ename:item.cargoEname,
                id: item.id,
                abbr:item.cargoNameAbbr
              })
          }
        }
      if(state.cargonames.length == 0){
        state.cargonames.push({
          id:'-1',
          value:'无匹配结果',
          disabled: true,
        })
      }
    })
    state.cargonamegroup = state.cargonames;
  },

  //海域名联想输入
  querySearchSea(state,queryString){
    state.seanames = []
    state.seaquery.name = queryString
    getseaname(state.seaquery).then(response => {

      if (response.error_code == 200)
        if(state.lang == 'en'){
          for (let item of response.datas) {
            state.seanames.push(
              {
                value: item.ename,
                cname:item.cname,
                id: item.id
              })
          }
        }
        else{
          for (let item of response.datas) {
            state.seanames.push(
              {
                value: item.cname,
                ename:item.ename,
                id: item.id
              })
          }
        }
      if(state.seanames.length == 0){
        state.seanames.push({
          id:'-1',
          value:'无匹配结果',
          disabled: true,
        })
      }
    })
    state.seanamegroup = state.seanames;
  },

  //港口名联想输入
  querySearchPort(state,query ){
    state.portnames = []
    state.portquery.portName = query.queryString
    state.portquery.seaAreaId = query.seaAreaId
    getportname(state.portquery).then(response => {
      if (response.error_code == 200)
        if(state.lang == 'en'){
          for (let item of response.datas) {
            state.portnames.push(
             /* {
                value: item.portEname===""? item.portEname:item.portCname,
                cname:item.portCname===""?item.portEname:item.portCname,
                id: item.id,
                seaAreaId:item.seaAreaId
              })*/
            {
                value: item.portEname,
                cname:item.portCname,
                id: item.id,
                seaAreaId:item.seaAreaId
              })
          }
        }
        else{
          for (let item of response.datas) {
            state.portnames.push(
              {
                value: item.portCname,
                ename:item.portEname,
                id: item.id,
                seaAreaId:item.seaAreaId
              })
          }
        }
      if(state.portnames.length == 0){
        state.portnames.push({
          id:'-1',
          seaAreaId:'',
          value:'无匹配结果',
          disabled: true,
        })
      }
    })
    state.portnamegroup = state.portnames;
  },

  //国家名联想输入
  querySearchCountry(state,queryString ){
    state.countrynames = []
    state.countryquery.name = queryString
    getcountryname(state.countryquery).then(response => {
      if (response.error_code == 200)
        if(state.lang == 'en'){
          for (let item of response.datas) {
            state.countrynames.push(
              {
                value: item.abbrEname,
                cname:item.abbrCname,
                id: item.id
              })
          }
        }
        else{
          for (let item of response.datas) {
            state.countrynames.push(
              {
                value: item.abbrCname,
                ename:item.abbrEname,
                id: item.id
              })
          }
        }
      if(state.countrynames.length == 0){
        state.countrynames.push({
          id:'-1',
          value:'无匹配结果',
          disabled: true,
        })
      }
    })
    state.countrynamegroup = state.countrynames;
  },
  //IMO（船舶编号）联想输入*****
  querySearchIMO(state,queryString){
    state.IMOnames = []
    state.IMOquery.IMOName = queryString
    getimoname(state.IMOquery).then(response => {
      if (response.error_code == 200)
        if(state.lang == 'en'){
          for (let item of response.datas) {
            state.IMOnames.push(
              {
                value: item.IMOEname,
                cname:item.IMOCname,
                id: item.id
              })
          }
        }
        else{
          for (let item of response.datas) {
            state.IMOnames.push(
              {
                value: item.IMOCname,
                ename:item.IMOEname,
                id: item.id
              })
          }
        }
      if(state.IMOnames.length == 0){
        state.IMOnames.push({
          id:'-1',
          value:'无匹配结果',
          disabled: true,
        })
      }
    })
    state.IMOnamegroup = state.IMOnames;
  },
  //公司名联想输入*****
  querySearchCompany(state,queryString){
    state.companynames = []
    state.companyquery.companyName = queryString
    getcompanyname(state.companyquery).then(response => {
      if (response.error_code == 200)
        if(state.lang == 'en'){
          for (let item of response.datas) {
            state.companynames.push(
              {
                value: item.companyEname===""?item.companyAbbr:item.companyEname,
                cname:item.companyCname===""?item.companyAbbr:item.companyCname,
                abbr:item.companyAbbr,
                id: item.id
              })
          }
        }
        else{
          for (let item of response.datas) {
            state.companynames.push(
              {
                value:item.companyCname===""?item.companyAbbr:item.companyCname,
                ename:item.companyEname===""?item.companyAbbr:item.companyEname,
                abbr:item.companyAbbr,
                id: item.id
              })
          }
        }
      if(state.companynames.length == 0){
        state.companynames.push({
          id:'-1',
          value:'无匹配结果',
          disabled: true,
        })
      }
    })
    state.companynamegroup = state.companynames;
  },
  querySearchVoyage(state,queryString){
    state.voyagenames = []
    state.voyagequery.voyageName = queryString
    getvoyagename(state.voyagequery).then(response => {
      if (response.error_code == 200)
        if(state.lang == 'en'){
          for (let item of response.datas) {
            state.voyagenames.push(
              {
                value: item.voyageEname,
                cname:item.voyageCname,
                id: item.id
              })
          }
        }
        else{
          for (let item of response.datas) {
            state.voyagenames.push(
              {
                value: item.voyageCname,
                ename:item.voyageEname,
                id: item.id
              })
          }
        }
      if(state.voyagenames.length == 0){
        state.voyagenames.push({
          id:'-1',
          value:'无匹配结果',
          disabled: true,
        })
      }
    })
    state.voyagenamegroup = state.voyagenames;
  },
  querySearchShipType(state,queryString){
    state.typenames = []
    state.typequery.voyageName = queryString
    getshiptype(state.typequery).then(response => {
      if (response.error_code == 200)
        if(state.lang == 'en'){
          for (let item of response.datas) {
            state.typenames.push(
              {
                value: item.ename,
                cname:item.cname,
                id: item.id
              })
          }
        }
        else{
          for (let item of response.datas) {
            state.typenames.push(
              {
                value: item.cname,
                ename:item.ename,
                id: item.id
              })
          }
        }
      if(state.typenames.length == 0){
        state.typenames.push({
          id:'-1',
          value:'无匹配结果',
          disabled: true
        })
      }
    })
    state.shiptypegroup = state.typenames;
  },
  //sender联想输入
  querySearchSender(state,queryString){
    state.sendernames = []
    state.senderquery.email = queryString
    getsender(state.senderquery).then(response => {
      if (response.error_code == 200)
          for (let item of response.datas) {
            state.sendernames.push(
              {
                value: item.email,
                name:item.name,
                id: item.id
              })
          }
    })
    state.sendergroup = state.sendernames;
  }
}

const actions={

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
