import zhLocale from 'element-plus/lib/locale/lang/zh-cn'
import Cookies from 'js-cookie'

const app = {
  sidebar: {
    opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
    withoutAnimation: false
  },
    state: {
          lang: localStorage.getItem('lang') ? localStorage.getItem('lang') : zhLocale.name ,//国际化语言
          isMobile:false
    },
    mutations: {
      TOGGLE_SIDEBAR: state => {
        state.sidebar.opened = !state.sidebar.opened
        state.sidebar.withoutAnimation = false
        if (state.sidebar.opened) {
          Cookies.set('sidebarStatus', 1)
        } else {
          Cookies.set('sidebarStatus', 0)
        }
      },
      CLOSE_SIDEBAR: (state, withoutAnimation) => {
        Cookies.set('sidebarStatus', 0)
        state.sidebar.opened = false
        state.sidebar.withoutAnimation = withoutAnimation
      },
       changeLanguage(state, lang) {
         state.lang = lang
         localStorage.setItem('lang',state.lang)
       },
       changeIsMobile: (state, isMobile) => {
        state.isMobile = isMobile
      }
    },
  actions:{
    toggleSideBar({ commit }) {
      commit('TOGGLE_SIDEBAR')
    },
    closeSideBar({ commit }, { withoutAnimation }) {
      commit('CLOSE_SIDEBAR', withoutAnimation)
    },
  }
  }
  
export default app