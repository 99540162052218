import { login, logout} from '../../api/metadata'
import { removeMenu} from '../../store/modules/global'
import { getToken, setToken, removeToken } from '../../utils/auth'
import router from '../../router/index'

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  loginStatus: '',
  username: '',
  enterCMS: '',
  mobile:'',
  status:'',
  accountemail:'',
  customerId:''
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
    //console.log(state.name)
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_LOGINSTATUS: (state, loginStatus) => {
    state.loginStatus = loginStatus
  },
  SET_ENTERCMS: (state, enterCMS) => {
    state.enterCMS = enterCMS
  },
  SET_USERNAME: (state, username) => {
    state.username = username
  },
  SET_DEFAULTEMAIL:(state,accountemail) =>{
    state.accountemail = accountemail
  },
  SET_MOBILE:(state , mobile)=>{
    state.mobile = mobile
  },
  SET_STATUS:(state, status)=>{
    state.status = status
  },
  SET_CUSTOMERID:(state,id)=>{
    state.customerId = id
  }

}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password }).then(response => {
        const { data } = response
        commit('SET_TOKEN', data.token)
        setToken(data.token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
/*  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token).then(response => {
        const { data } = response

        if (!data) {
          reject('Verification failed, please Login again.')
        }

        const { name, avatar } = data

        commit('SET_NAME', name)
        commit('SET_AVATAR', avatar)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },*/

  // user logout
  logout({ commit, state ,dispatch}) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        commit('SET_TOKEN', '')
        removeToken()
        dispatch('global/removeMenu',[],{root:true})
        router.push('/login')
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      removeToken()
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

