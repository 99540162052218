const getters = {
  lang:state => state.metadata.lang,
    menu: state => state.global.menu,
    sidebar: state => state.app.sidebar,
    matchobj: state => state.metadata.matchobj,
    tonnageList: state => state.metadata.tonnageList,
    cargoList: state => state.metadata.cargoList,
    tctList: state => state.metadata.tctList,
    voyageplanList: state => state.metadata.voyageplanList,
    selectId:state => state.metadata.selectId,
    token: state => state.user.token,
    isSideBarMobileFold:state => state.fold.isSideBarMobileFold,
    permission:state =>state.global.permission,
    portnamegroup:state => state.metadata.portnamegroup,
      cargonamegroup:state => state.metadata.cargonamegroup,
      companynamegroup:state => state.metadata.companynamegroup,
      vesselnamegroup:state => state.metadata.vesselnamegroup,
      voyagenamegroup:state => state.metadata.voyagenamegroup,
      seanamegroup:state => state.metadata.seanamegroup,
      countrynamegroup:state => state.metadata.countrynamegroup,
      linernamegroup:state => state.metadata.linernamegroup,
      shiptypegroup:state => state.metadata.shiptypegroup,
      IMOnamegroup:state => state.metadata.IMOnamegroup,
      sendergroup:state => state.metadata.sendergroup,
      accountemail:state=>state.user.accountemail,
    isHaiTuDrawer:state => state.fold.isHaiTuDrawer,
  loginStatus: (state) => state.user.loginStatus,
  name: (state) => state.user.name,
  mobile: (state) => state.user.mobile,
  status: (state) => state.user.status,
    isMobile:state => state.app.isMobile,
  customerId:state =>state.user.customerId,
  username:state =>state.user.username,
    dialogWidth:state => {
      if(state.app.isMobile) return '65%'
      else return '30%'
    }
  }

export default getters